import { gql } from '@apollo/client';

export const GET_REQUESTS = gql`
    query GetRequests {
        requests {
            id
            traveler
            approverId
            approver {
                id
                givenName

                familyName
                email
                userApproverTypes {
                    approverType {
                        id
                        name
                    }
                }
                createdDate
                lastModifiedDate
            }
            destination
            international
            startDate
            endDate
            purpose
            costEstimate
            funding
            attendees
            noteToApprover
            statusId
            status {
                id
                value
                title
                createdDate
                lastModifiedDate
            }
            approverComment
            history {
                id
                field
                oldValue
                newValue
                createdBy {
                    id
                    givenName
                    familyName
                    email
                    createdDate
                    lastModifiedDate
                }
                createdDate
            }
            createdById
            createdBy {
                id
                givenName

                familyName
                email
                userApproverTypes {
                    approverType {
                        id
                        name
                    }
                }
                createdDate
                lastModifiedDate
            }
            createdDate
            lastModifiedDate
        }
    }
`;

export const GET_REQUEST_BY_ID = gql`
    query GetRequestById($requestId: Float!) {
        request(requestId: $requestId) {
            id
            traveler
            approverId
            approver {
                id
                givenName

                familyName
                email
                userApproverTypes {
                    approverType {
                        id
                        name
                    }
                }
                createdDate
                lastModifiedDate
            }
            destination
            international
            startDate
            endDate
            purpose
            costEstimate
            funding
            attendees
            noteToApprover
            statusId
            status {
                id
                value
                title
                createdDate
                lastModifiedDate
            }
            approverComment
            history {
                id
                field
                oldValue
                newValue
                createdBy {
                    id
                    givenName
                    familyName
                    email
                    createdDate
                    lastModifiedDate
                }
                createdDate
            }
            createdById
            createdBy {
                id
                givenName

                familyName
                email
                userApproverTypes {
                    approverType {
                        id
                        name
                    }
                }
                createdDate
                lastModifiedDate
            }
            createdDate
            lastModifiedDate
        }
    }
`;

export const GET_REQUESTS_CREATED_BY_USER = gql`
    query GetRequestsCreatedByUser($userId: String!) {
        requestsCreatedByUser(userId: $userId) {
            id
            traveler
            approverId
            approver {
                id
                givenName

                familyName
                email
                userApproverTypes {
                    approverType {
                        id
                        name
                    }
                }
                createdDate
                lastModifiedDate
            }
            destination
            international
            startDate
            endDate
            purpose
            costEstimate
            funding
            attendees
            noteToApprover
            statusId
            status {
                id
                value
                title
                createdDate
                lastModifiedDate
            }
            approverComment
            history {
                id
                field
                oldValue
                newValue
                createdBy {
                    id
                    givenName

                    familyName
                    email
                    createdDate
                    lastModifiedDate
                }
                createdDate
            }
            createdById
            createdBy {
                id
                givenName

                familyName
                email
                userApproverTypes {
                    approverType {
                        id
                        name
                    }
                }
                createdDate
                lastModifiedDate
            }
            createdDate
            lastModifiedDate
        }
    }
`;

export const GET_REQUESTS_BY_TRAVELER = gql`
    query GetRequestsByTraveler($userId: String!) {
        requestsByTraveler(userId: $userId) {
            id
            traveler
            approverId
            approver {
                id
                givenName

                familyName
                email
                userApproverTypes {
                    approverType {
                        id
                        name
                    }
                }
                createdDate
                lastModifiedDate
            }
            destination
            international
            startDate
            endDate
            purpose
            costEstimate
            funding
            attendees
            noteToApprover
            statusId
            status {
                id
                value
                title
                createdDate
                lastModifiedDate
            }
            approverComment
            history {
                id
                field
                oldValue
                newValue
                createdBy {
                    id
                    givenName

                    familyName
                    email
                    createdDate
                    lastModifiedDate
                }
                createdDate
            }
            createdById
            createdBy {
                id
                givenName

                familyName
                email
                userApproverTypes {
                    approverType {
                        id
                        name
                    }
                }
                createdDate
                lastModifiedDate
            }
            createdDate
            lastModifiedDate
        }
    }
`;

export const GET_REQUESTS_BY_APPROVER = gql`
    query GetRequestsByApprover($approverId: String!) {
        requestsByApprover(approverId: $approverId) {
            id
            traveler
            approverId
            approver {
                id
                givenName

                familyName
                email
                userApproverTypes {
                    approverType {
                        id
                        name
                    }
                }
                createdDate
                lastModifiedDate
            }
            destination
            international
            startDate
            endDate
            purpose
            costEstimate
            funding
            attendees
            noteToApprover
            statusId
            status {
                id
                value
                title
                createdDate
                lastModifiedDate
            }
            approverComment
            history {
                id
                field
                oldValue
                newValue
                createdBy {
                    id
                    givenName

                    familyName
                    email
                    createdDate
                    lastModifiedDate
                }
                createdDate
            }
            createdById
            createdBy {
                id
                givenName

                familyName
                email
                userApproverTypes {
                    approverType {
                        id
                        name
                    }
                }
                createdDate
                lastModifiedDate
            }
            createdDate
            lastModifiedDate
        }
    }
`;

export const GET_REQUESTS_BY_STATUS = gql`
    query GetRequestsByStatus($statusId: Float!) {
        requestsByStatus(statusId: $statusId) {
            id
            traveler
            approverId
            approver {
                id
                givenName

                familyName
                email
                userApproverTypes {
                    approverType {
                        id
                        name
                    }
                }
                createdDate
                lastModifiedDate
            }
            destination
            international
            startDate
            endDate
            purpose
            costEstimate
            funding
            attendees
            noteToApprover
            statusId
            status {
                id
                value
                title
                createdDate
                lastModifiedDate
            }
            approverComment
            history {
                id
                field
                oldValue
                newValue
                createdBy {
                    id
                    givenName

                    familyName
                    email
                    createdDate
                    lastModifiedDate
                }
                createdDate
            }
            createdById
            createdBy {
                id
                givenName

                familyName
                email
                userApproverTypes {
                    approverType {
                        id
                        name
                    }
                }
                createdDate
                lastModifiedDate
            }
            createdDate
            lastModifiedDate
        }
    }
`;
export default {
    GET_REQUESTS,
    GET_REQUESTS_CREATED_BY_USER,
    GET_REQUESTS_BY_TRAVELER,
    GET_REQUESTS_BY_APPROVER,
    GET_REQUESTS_BY_STATUS,
};
