import { useLazyQuery, useMutation } from '@apollo/client';
import { CircularProgress, Container, Paper, Typography } from '@material-ui/core';
import { CREATE_REQUEST, UPDATE_REQUEST } from 'api/mutations/request';
import { GET_REQUEST_BY_ID } from 'api/queries/request';
import Request from 'classes/Request';
import NotFound from 'pages/NotFound/NotFound';
import TravelRequestDetails from 'pages/TravelRequest/TravelRequestDetails';
import TravelRequestForm from 'pages/TravelRequest/TravelRequestForm';
import TravelRequestReview from 'pages/TravelRequest/TravelRequestReview';
import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { selectCurrentUser, updateAlert } from 'store/appSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addRequest, selectCurrentRequest, updateCurrentRequest, updateRequest } from 'store/requestSlice';
import { User } from 'types/User';
import './TravelRequest.scss';

interface Props {
    mode?: 'view' | 'edit' | 'review';
}

interface RouteParams {
    id: string;
}

const TravelRequest = ({ mode = 'edit' }: Props): ReactElement => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { id } = useParams<RouteParams>();
    const travelRequest = useAppSelector(selectCurrentRequest);
    const currentUser: User = useAppSelector(selectCurrentUser);
    const [loading, setLoading] = useState<boolean>(true);
    const [responseNotFound, setResponseNotFound] = useState<boolean>(false);

    const [createRequest] = useMutation(CREATE_REQUEST, {
        onCompleted: (response): void => {
            dispatch(addRequest(Request.transformer(response.createRequest)));
            history.push('/');
            dispatch(
                updateAlert({
                    visible: true,
                    message: 'Travel request submitted',
                    severity: 'success',
                })
            );
        },
        onError: (): void => {
            dispatch(
                updateAlert({
                    visible: true,
                    message: 'Error submitting travel request',
                    severity: 'error',
                })
            );
        },
    });

    const [modifyRequest] = useMutation(UPDATE_REQUEST, {
        onCompleted: (response): void => {
            dispatch(updateRequest(Request.transformer(response.updateRequest)));
            history.push('/');
            dispatch(
                updateAlert({
                    visible: true,
                    message: 'Travel request updated',
                    severity: 'success',
                })
            );
        },
        onError: (): void => {
            dispatch(
                updateAlert({
                    visible: true,
                    message: 'Error updating travel request',
                    severity: 'error',
                })
            );
        },
    });

    const [getTravelRequestById] = useLazyQuery(GET_REQUEST_BY_ID, {
        fetchPolicy: 'network-only',
        onCompleted: (response) => {
            dispatch(updateCurrentRequest(Request.transformer(response.request)));
            setLoading(false);
            setResponseNotFound(false);
        },
        onError: () => {
            setResponseNotFound(true);
        },
    });

    const handleOnSubmit = async (): Promise<void> => {
        if (id && id !== '0') {
            await modifyRequest({
                variables: {
                    requestId: travelRequest.id,
                    data: Request.getMutation(travelRequest),
                },
            });
        } else {
            await createRequest({
                variables: {
                    data: { ...Request.getMutation(travelRequest), createdById: currentUser?.id },
                },
            });
        }
    };

    useEffect(() => {
        if (id && id !== '0') {
            getTravelRequestById({
                variables: {
                    requestId: +id,
                },
            });
        } else {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    if (responseNotFound) {
        return <NotFound />;
    }

    return (
        <>
            {mode === 'edit' && (
                // create or edit travel request
                <Container className="TravelRequest" maxWidth="md">
                    {loading && <CircularProgress className="TravelRequest__loading" size={40} aria-label="Loading" />}
                    {!loading && (
                        <>
                            <Typography color="primary" variant="h2" gutterBottom>
                                Request Travel
                            </Typography>
                            <Paper variant="outlined" elevation={3}>
                                <TravelRequestForm />
                            </Paper>
                        </>
                    )}
                </Container>
            )}
            {mode === 'review' && (
                // review travel request
                <Container className="TravelRequest" maxWidth="md">
                    <Typography color="primary" variant="h2" gutterBottom>
                        Request Travel
                    </Typography>
                    <Typography className="TravelRequest__review-title" color="primary" variant="h3" gutterBottom>
                        Please review the entered information and confirm it is correct before submitting your request
                    </Typography>
                    <Paper variant="outlined" elevation={3}>
                        <TravelRequestReview onSubmit={handleOnSubmit} />
                    </Paper>
                </Container>
            )}
            {mode === 'view' && (
                // view existing travel request
                <Container className="TravelRequest" maxWidth="lg">
                    {loading && <CircularProgress className="TravelRequest__loading" size={40} aria-label="Loading" />}
                    {!loading && <TravelRequestDetails />}
                </Container>
            )}
        </>
    );
};

export default TravelRequest;
