import { Breadcrumbs, Button, Divider, Link, Paper, Typography } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { DATE_FORMAT } from 'App';
import BaseDialog from 'components/BaseDialog/BaseDialog';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { ReactElement, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { selectStatuses, selectUsers } from 'store/appSlice';
import { useAppSelector } from 'store/hooks';
import { selectCurrentRequest } from 'store/requestSlice';
import { RequestHistory } from 'types/RequestHistory';
import { expenseFormatter } from 'utils/formatter';

const TravelRequestDetails = (): ReactElement => {
    const history = useHistory();
    const travelRequest = useAppSelector(selectCurrentRequest);
    const sortedHistory = travelRequest?.history
        ?.slice(0)
        .sort((a: RequestHistory, b: RequestHistory) => (a.createdDate < b.createdDate ? 1 : -1));
    const [dialogOpen, setDialogOpen] = useState(false);
    const formattedExpense = expenseFormatter.format(travelRequest.costEstimate ? +travelRequest.costEstimate : 0);
    const activityHistoryDateFormat = 'MM/DD/YYYY';
    const activityHistoryTimeFormat = 'HH[:]mm';
    const users = useAppSelector(selectUsers);
    const statuses = useAppSelector(selectStatuses);

    const handleEdit = (): void => {
        if (travelRequest.status?.value === 'Pending' || travelRequest.status?.value === 'Details Requested') {
            history.push(`/request-travel/${travelRequest.id}/edit`);
        } else {
            setDialogOpen(true);
        }
    };

    const translateFieldNameToDisplayName = (fieldName: string): string => {
        let displayName: string;
        switch (fieldName) {
            case 'approverComment':
                displayName = 'Approver Comment';
                break;
            case 'costEstimate':
                displayName = 'Cost Estimate';
                break;
            case 'endDate':
                displayName = 'End Date';
                break;
            case 'startDate':
                displayName = 'Start Date';
                break;
            case 'noteToApprover':
                displayName = 'Additional Notes';
                break;
            case 'approverId':
                displayName = 'Approver';
                break;
            case 'statusId':
                displayName = 'Status';
                break;
            case 'international':
                displayName = 'International Trip';
                break;
            default:
                displayName = fieldName[0].toUpperCase() + fieldName.substring(1);
        }
        return displayName;
    };

    /* eslint-disable no-case-declarations */
    const getChangedFieldValue = (fieldName: string, value: any): any => {
        let fieldValue: string;
        switch (fieldName) {
            case 'approverId':
                const approver = users.find((user) => user.id === value);
                fieldValue = approver ? `${approver.givenName} ${approver.familyName}` : 'Undefined';
                break;
            case 'statusId':
                const status = statuses.find((s) => s.id === +value);
                fieldValue = status ? status.title : 'Undefined';
                break;
            case 'costEstimate':
                fieldValue = expenseFormatter.format(+value);
                break;
            default:
                fieldValue = value;
        }
        return fieldValue;
    };

    return (
        <>
            {travelRequest && (
                <div className="TravelRequest TravelRequest__details">
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                        <Link component={RouterLink} to="/">
                            Home
                        </Link>
                        <Typography color="primary">Travel Request Details</Typography>
                    </Breadcrumbs>
                    <div className="TravelRequest__details-header">
                        <Typography color="primary" variant="h2">
                            Travel Request
                        </Typography>
                        <div className="TravelRequest__details-actions">
                            <Button variant="outlined" color="primary" onClick={() => history.push(`/`)}>
                                Back
                            </Button>
                            <Button
                                disabled={
                                    travelRequest.status?.value === 'Approved Level 2' ||
                                    travelRequest.status?.value === 'Denied'
                                }
                                variant="contained"
                                color="primary"
                                onClick={handleEdit}
                            >
                                Edit Request
                            </Button>
                        </div>
                    </div>
                    <div className="TravelRequest__details-main">
                        <Paper>
                            <Typography variant="h3" gutterBottom>
                                General Information
                            </Typography>
                            <div className="FlexLayout">
                                <div className="FlexLayout__quarter">
                                    <Typography variant="body1">Traveler</Typography>
                                    <Typography variant="body2">{travelRequest.traveler}</Typography>
                                </div>
                                <div className="FlexLayout__quarter">
                                    <Typography variant="body1">Prepared By</Typography>
                                    <Typography variant="body2">{`${travelRequest.createdBy?.givenName} ${travelRequest.createdBy?.familyName}`}</Typography>
                                </div>
                                <div className="FlexLayout__quarter">
                                    <Typography variant="body1">Approver</Typography>
                                    <Typography variant="body2">{`${travelRequest.approver?.givenName} ${travelRequest.approver?.familyName}`}</Typography>
                                </div>
                                <div className="FlexLayout__quarter">
                                    <Typography variant="body1">Trip Dates</Typography>
                                    <Typography variant="body2">
                                        {dayjs(travelRequest.startDate).format(DATE_FORMAT)} &ndash;&nbsp;
                                        {dayjs(travelRequest.endDate).format(DATE_FORMAT)}
                                    </Typography>
                                </div>
                            </div>
                            <div className="FlexLayout">
                                <div className="FlexLayout__third">
                                    <Typography variant="body1">Trip Location(s)</Typography>
                                    <Typography variant="body2" className="TravelDestination">
                                        {travelRequest.destination}
                                    </Typography>
                                </div>
                                <div className="FlexLayout__third">
                                    <Typography variant="body1">International Trip?</Typography>
                                    <Typography variant="body2">
                                        {travelRequest.international ? 'Yes' : 'No'}
                                    </Typography>
                                </div>
                                <div className="FlexLayout__third">
                                    <Typography variant="body1">Reason for Trip</Typography>
                                    <Typography variant="body2">{travelRequest.purpose}</Typography>
                                </div>
                            </div>
                            <Divider variant="fullWidth" />
                            <Typography variant="h3" color="primary">
                                Expenses and Additional Details
                            </Typography>
                            <Typography variant="h5" gutterBottom color="textPrimary">
                                Estimate must come from DTS
                            </Typography>
                            <div className="FlexLayout">
                                <div className="FlexLayout__third">
                                    <Typography variant="body1">Estimated Expenses</Typography>
                                    <Typography variant="body2">{formattedExpense}</Typography>
                                </div>
                                <div className="FlexLayout__third">
                                    <Typography variant="body1">Who is Funding Trip?</Typography>
                                    <Typography variant="body2">{travelRequest.funding || 'N/A'}</Typography>
                                </div>
                                <div className="FlexLayout__third">
                                    <Typography variant="body1">Non-AFWERX Attendees?</Typography>
                                    <Typography variant="body2">{travelRequest.attendees ? 'Yes' : 'No'}</Typography>
                                </div>
                            </div>
                            <Typography variant="body1">Additional Notes</Typography>
                            <Typography variant="body2">{travelRequest.noteToApprover || 'N/A'}</Typography>
                        </Paper>
                        <div>
                            <Paper className="TravelRequest__details-main-status">
                                <ul>
                                    <li className={`Status__${_.toLower(travelRequest.status?.title)}`}>
                                        <Typography variant="h3">Status</Typography>
                                        <Typography variant="body1">
                                            {travelRequest.status?.title === 'Denied'
                                                ? `${travelRequest.status?.title} - Please cancel this trip in DTS`
                                                : travelRequest.status?.title}
                                        </Typography>
                                        {travelRequest.approverComment && (
                                            <blockquote>
                                                <Typography variant="body2">{travelRequest.approverComment}</Typography>
                                            </blockquote>
                                        )}
                                    </li>
                                    <li>
                                        <Typography variant="h3">Initiated</Typography>
                                        <Typography variant="body2">
                                            {dayjs(travelRequest.createdDate).format(DATE_FORMAT)}
                                        </Typography>
                                    </li>
                                    {travelRequest.lastModifiedDate && (
                                        <li>
                                            <Typography variant="h3">Updated</Typography>
                                            <Typography variant="body2">
                                                {dayjs(travelRequest.lastModifiedDate).format(DATE_FORMAT)}
                                            </Typography>
                                        </li>
                                    )}
                                </ul>
                            </Paper>

                            <Paper className="TravelRequest__details-main-status-bottom">
                                <ul>
                                    <li className={`ActivityHistory__${_.toLower(travelRequest.status?.title)}`}>
                                        <div className="Title">
                                            <div>
                                                <Typography display="inline" variant="h3">
                                                    Activity History{' '}
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography display="inline" variant="h3" className="TripNumber">
                                                    Trip #{travelRequest.id}
                                                </Typography>
                                            </div>
                                        </div>
                                    </li>
                                    {sortedHistory?.map((historyItem) => (
                                        <li className="HistoryItem">
                                            <Typography display="inline" variant="body2" className="DateTime">
                                                {' '}
                                                {dayjs(historyItem.createdDate).format(activityHistoryDateFormat)}
                                            </Typography>
                                            <Typography display="inline" variant="body2">
                                                {' '}
                                                at
                                            </Typography>
                                            <Typography display="inline" variant="body2" className="DateTime">
                                                {' '}
                                                {dayjs(historyItem.createdDate).format(activityHistoryTimeFormat)}
                                            </Typography>
                                            <br />
                                            <Typography display="inline" variant="body1">
                                                {historyItem.createdBy?.givenName} {historyItem.createdBy?.familyName}{' '}
                                            </Typography>
                                            <Typography display="inline" variant="body2">
                                                updated the{' '}
                                            </Typography>
                                            <Typography display="inline" variant="body1">
                                                {translateFieldNameToDisplayName(historyItem.field)}
                                            </Typography>

                                            {historyItem.field === 'startDate' || historyItem.field === 'endDate' ? (
                                                <div className="FieldChange">
                                                    <Typography display="inline" variant="body2" className="ValueText">
                                                        {dayjs(historyItem.oldValue).format(activityHistoryDateFormat)}{' '}
                                                    </Typography>

                                                    <Typography display="inline" variant="body2" className="ValueText">
                                                        <ArrowForward fontSize="small" />
                                                    </Typography>
                                                    <Typography
                                                        display="inline"
                                                        variant="body2"
                                                        className="ValueText-new"
                                                    >
                                                        {dayjs(historyItem.newValue).format(activityHistoryDateFormat)}
                                                    </Typography>
                                                </div>
                                            ) : (
                                                <div className="FieldChange">
                                                    <Typography display="inline" variant="body2" className="ValueText">
                                                        {getChangedFieldValue(historyItem.field, historyItem.oldValue)}{' '}
                                                    </Typography>
                                                    <Typography display="inline" variant="body2" className="ValueText">
                                                        <ArrowForward fontSize="small" />
                                                    </Typography>
                                                    <Typography
                                                        display="inline"
                                                        variant="body2"
                                                        className="ValueText-new"
                                                    >
                                                        {getChangedFieldValue(historyItem.field, historyItem.newValue)}
                                                    </Typography>
                                                </div>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </Paper>
                        </div>

                        <BaseDialog
                            handleOpen={dialogOpen}
                            handleClose={() => setDialogOpen(false)}
                            title="Warning"
                            contentText="This request is already in the approval process. If you edit the request, the approval process will reset upon submission. Are you sure you want to continue?"
                            confirmText="Yes"
                            handleConfirm={() => history.push(`/request-travel/${travelRequest.id}/edit`)}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default TravelRequestDetails;
