import { gql } from '@apollo/client';

export const GET_APPROVER_TYPES = gql`
    query GetApproverTypes {
        approverTypes {
            id
            name
        }
    }
`;
export default { GET_APPROVER_TYPES };
