import { gql } from '@apollo/client';

export const CHANGE_USER_APPROVER_TYPE = gql`
    mutation ChangeUserApproverType($userId: String!, $approverTypeName: String!) {
        changeUserApproverType(userId: $userId, approverTypeName: $approverTypeName) {
            id
            user {
                id
                givenName
                familyName
            }
            approverType {
                id
                name
            }
        }
    }
`;

export const DELETE_USERS_USER_APOPROVER_TYPES = gql`
    mutation DeleteUsersUserApproverTypes($userId: String!) {
        deleteUsersUserApproverTypes(userId: $userId) {
            user {
                id
                givenName
                familyName
            }
            approverType {
                id
                name
            }
        }
    }
`;

export default { CHANGE_USER_APPROVER_TYPE, DELETE_USERS_USER_APOPROVER_TYPES };
