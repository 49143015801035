import React, { ReactElement } from 'react';
import { Container, Typography } from '@material-ui/core';
import './NotFound.scss';

const NotFound = (): ReactElement => (
    <Container maxWidth="sm" className="NotFound">
        <Typography variant="h1">404</Typography>
        <Typography variant="h2">Page not found</Typography>
    </Container>
);

export default NotFound;
