import DateFnsUtils from '@date-io/date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    Divider,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DATE_FORMAT } from 'App';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useHistory, useLocation } from 'react-router-dom';
import { selectCurrentUser, selectUsers, updateAlert } from 'store/appSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectCurrentRequest, updateCurrentRequest } from 'store/requestSlice';
import { ApproverType } from 'types/ApproverType';
import { User } from 'types/User';
import * as yup from 'yup';
import './TravelRequest.scss';

interface IFormInputs {
    traveler: string;
    createdBy: User | null;
    approver: User | null;
    startDate: string | null;
    endDate: string | null;
    destination: string;
    international: boolean;
    purpose: string;
    costEstimate: number | null;
    funding: string;
    attendees: boolean;
    noteToApprover: string;
}

const TravelRequestForm = (): ReactElement => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const travelRequest = useAppSelector(selectCurrentRequest);
    const currentUser = useAppSelector(selectCurrentUser);
    const users = useAppSelector(selectUsers);
    const [approvers, setApprovers] = useState<User[]>([]);
    // Use separate var to track approver to enable a null value in autocomplete. This avoids an uncontrolled/controlled error.
    const url = location.pathname.split('/')[3] || '';
    const approverId = url.includes('edit') ? travelRequest?.approver?.id : currentUser.approverId;
    const [approver, setApprover] = useState<any>(_.filter(users, (user) => user.id === approverId)[0]);

    const schema = yup.object().shape({
        traveler: yup.string().required(),
        approver: yup
            .mixed()
            .test(
                'not-self',
                'Cannot approve own Travel Request',
                () => travelRequest.approver?.id !== travelRequest.createdBy?.id
            ),
        startDate: yup
            .date()
            .typeError('Please enter a valid date')
            .test('startDate', 'Start Date cannot be after return date', (value: any, context: any) =>
                context.options.parent.endDate && dayjs(context.options.parent.endDate).isValid()
                    ? dayjs(value).isBefore(dayjs(context.options.parent.endDate))
                    : true
            ),
        endDate: yup
            .date()
            .typeError('Please enter a valid date')
            .test('endDate', 'Return date must be after start date', (value: any, context: any) =>
                context.options.parent.startDate && dayjs(context.options.parent.startDate).isValid()
                    ? dayjs(value).isAfter(dayjs(context.options.parent.startDate))
                    : true
            ),
        destination: yup.string().required(),
        international: yup.boolean().default(false),
        purpose: yup.string().required(),
        costEstimate: yup
            .number()
            .transform((value, originalValue) =>
                originalValue ? parseFloat(_.replace(originalValue, new RegExp(',', 'g'), '')) : value
            )
            .required()
            .min(0, 'Expenses cannot be a negative number')
            .typeError('Expenses must be a number'),
        funding: yup.string().required(),
        attendees: yup.boolean().default(false),
        noteToApprover: yup.string(),
    });

    const {
        control,
        handleSubmit,
        setValue,
        getValues,
        reset,
        watch,
        clearErrors,
        formState: { errors, isValid },
    } = useForm<IFormInputs>({
        mode: 'all',
        criteriaMode: 'all',
        resolver: yupResolver(schema),
        defaultValues: {
            traveler: travelRequest.traveler,
            approver,
            createdBy: travelRequest.createdBy,
            startDate: travelRequest.startDate,
            endDate: travelRequest.endDate,
            destination: travelRequest.destination,
            international: travelRequest.international || false,
            purpose: travelRequest.purpose,
            costEstimate: travelRequest.costEstimate,
            funding: travelRequest.funding,
            attendees: travelRequest.attendees || false,
            noteToApprover: travelRequest.noteToApprover,
        },
    });

    const approverProps = {
        options: approvers,
        getOptionSelected: (option: User, value: User) => option.id === value.id,
        getOptionLabel: (option: User) =>
            option.familyName && option.givenName ? `${option.familyName}, ${option.givenName}` : '',
    };

    const getUserApproverTypes = (user: User): ApproverType[] =>
        user.userApproverTypes ? _.map(_.filter(user.userApproverTypes, 'approverType'), 'approverType') : [];

    const handleReview: SubmitHandler<IFormInputs> = (data) => {
        if (isValid) {
            dispatch(
                updateCurrentRequest({
                    ...travelRequest,
                    traveler: data.traveler,
                    approver,
                    createdBy: data.createdBy || currentUser,
                    startDate: data.startDate,
                    endDate: data.endDate,
                    destination: data.destination,
                    international: data.international,
                    purpose: data.purpose,
                    costEstimate: data.costEstimate,
                    funding: data.funding,
                    attendees: data.attendees,
                    noteToApprover: data.noteToApprover,
                })
            );
            history.push(travelRequest.id ? `/request-travel/${travelRequest.id}/review` : '/request-travel/0/review');
        } else {
            dispatch(
                updateAlert({
                    visible: true,
                    message: 'Please complete all required fields',
                    severity: 'error',
                })
            );
        }
    };

    useEffect(() => {
        setApprovers(
            _.filter(users, (user: User) =>
                getUserApproverTypes(user).some((userApproverType) => userApproverType.name === 'Approver Type 1')
            )
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users]);

    useEffect(() => {
        if (currentUser && currentUser.id) {
            dispatch(updateCurrentRequest({ ...travelRequest }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser]);

    useEffect(() => {
        if (!approver) {
            setApprover(travelRequest.approver);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [travelRequest]);

    useEffect(() => {
        const startDateValue = getValues('startDate');
        const endDateValue = getValues('endDate');
        if (startDateValue !== null && endDateValue === null) {
            const endDate = new Date(startDateValue);
            endDate.setDate(endDate.getDate() + 1);
            setValue('endDate', endDate.toString());
        }
        if (endDateValue !== null && startDateValue === null) {
            const startDate = new Date(endDateValue);
            startDate.setDate(startDate.getDate() - 1);
            setValue('startDate', startDate.toString());
        }
        if (startDateValue && endDateValue) {
            if (startDateValue < endDateValue) {
                clearErrors('startDate');
                clearErrors('endDate');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch('startDate'), watch('endDate')]);

    return (
        <div className="TravelRequest TravelRequest__form">
            <form id="travelRequest" onSubmit={handleSubmit(handleReview)}>
                <Typography color="primary" variant="h3">
                    General Information
                </Typography>
                <div className="FlexLayout">
                    <FormControl fullWidth margin="normal">
                        <Controller
                            name="traveler"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    required
                                    id="traveler"
                                    variant="outlined"
                                    label="Traveler"
                                    margin="normal"
                                />
                            )}
                        />
                    </FormControl>

                    <FormControl fullWidth margin="normal">
                        <Controller
                            name="createdBy"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    id="preparer"
                                    variant="outlined"
                                    label="Prepared By"
                                    margin="normal"
                                    disabled
                                    value={`${currentUser?.familyName}, ${currentUser?.givenName}`}
                                />
                            )}
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <Controller
                            name="approver"
                            control={control}
                            render={({ field }) => (
                                <Autocomplete
                                    {...field}
                                    {...approverProps}
                                    data-testid="approver-input"
                                    aria-controls="approver"
                                    options={approvers}
                                    disableClearable
                                    autoComplete
                                    includeInputInList
                                    value={approver || null}
                                    onChange={(_event: ChangeEvent<unknown>, value: User): void => {
                                        setApprover(value);
                                        dispatch(updateCurrentRequest({ ...travelRequest, approver: value }));
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            id="approver"
                                            variant="outlined"
                                            label="Approver"
                                            margin="normal"
                                            error={!!errors.approver}
                                        />
                                    )}
                                />
                            )}
                        />
                    </FormControl>
                </div>
                <div className="FlexLayout">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <FormControl fullWidth margin="normal">
                            <Controller
                                name="startDate"
                                control={control}
                                render={({ field }) => (
                                    <KeyboardDatePicker
                                        {...field}
                                        ref={null}
                                        id="startDate"
                                        required
                                        autoOk
                                        disableToolbar
                                        inputVariant="outlined"
                                        variant="inline"
                                        format="yyyy-MM-dd"
                                        margin="normal"
                                        value={getValues('startDate') || null}
                                        placeholder={DATE_FORMAT}
                                        label="Trip Start Date"
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        error={!!errors.startDate}
                                        helperText={errors.startDate?.message || `Date format must be ${DATE_FORMAT}`}
                                    />
                                )}
                            />
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <Controller
                                name="endDate"
                                control={control}
                                render={({ field }) => (
                                    <KeyboardDatePicker
                                        {...field}
                                        ref={null}
                                        required
                                        id="endDate"
                                        autoOk
                                        disableToolbar
                                        inputVariant="outlined"
                                        variant="inline"
                                        format="yyyy-MM-dd"
                                        margin="normal"
                                        value={getValues('endDate') || null}
                                        placeholder={DATE_FORMAT}
                                        label="Trip End Date"
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        error={!!errors.endDate}
                                        helperText={errors.endDate?.message || `Date format must be ${DATE_FORMAT}`}
                                    />
                                )}
                            />
                        </FormControl>
                    </MuiPickersUtilsProvider>
                    <FormControl
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        className="TravelRequest__form-noHelperText"
                    >
                        <InputLabel htmlFor="international" className="TravelRequest__form-select-label">
                            Is Trip International?
                        </InputLabel>
                        <Controller
                            name="international"
                            control={control}
                            render={({ field }) => (
                                <Select className="TravelRequest__form-select-field" id="international" {...field}>
                                    <MenuItem value="true">Yes</MenuItem>
                                    <MenuItem value="false">No</MenuItem>
                                </Select>
                            )}
                        />
                    </FormControl>
                </div>
                <div className="FlexLayout">
                    <FormControl variant="outlined" fullWidth margin="normal">
                        <Controller
                            name="destination"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    id="destination"
                                    label="Trip Location(s)"
                                    variant="outlined"
                                    multiline
                                    rows={4}
                                    required
                                    error={!!errors.destination}
                                    helperText={
                                        errors.destination?.message || 'Please use a separate line for each location'
                                    }
                                />
                            )}
                        />
                    </FormControl>
                    <FormControl variant="outlined" fullWidth margin="normal">
                        <Controller
                            name="purpose"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    id="purpose"
                                    label="Reason for trip"
                                    variant="outlined"
                                    multiline
                                    rows={4}
                                    required
                                    error={!!errors.purpose}
                                    helperText={errors.purpose?.message}
                                />
                            )}
                        />
                    </FormControl>
                </div>
                <Divider variant="fullWidth" />
                <Typography color="primary" variant="h3">
                    Expenses and Additional Details
                </Typography>
                <Typography variant="h5" gutterBottom color="textPrimary">
                    Estimate must come from DTS
                </Typography>
                <div className="FlexLayout">
                    <FormControl fullWidth margin="normal">
                        <Controller
                            name="costEstimate"
                            control={control}
                            render={({ field }) => (
                                <NumberFormat
                                    {...field}
                                    customInput={TextField}
                                    id="costEstimate"
                                    variant="outlined"
                                    label="Estimated expenses"
                                    required
                                    decimalScale={2}
                                    fixedDecimalScale
                                    thousandSeparator
                                    onValueChange={(v) => field.onChange(v.value)}
                                    value={field.value}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    error={!!errors.costEstimate}
                                    helperText={errors.costEstimate?.message}
                                />
                            )}
                        />
                    </FormControl>
                    <FormControl variant="outlined" fullWidth margin="normal">
                        <InputLabel htmlFor="funding" className="TravelRequest__form-select-label">
                            Who is funding this trip?
                        </InputLabel>
                        <Controller
                            name="funding"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    className="TravelRequest__form-select-field"
                                    id="funding"
                                    data-testid="funding-select"
                                    {...field}
                                >
                                    <MenuItem value="SBIR/STTR">SBIR/STTR</MenuItem>
                                    <MenuItem value="Agility Prime">Agility Prime</MenuItem>
                                    <MenuItem value="AFWERX">AFWERX</MenuItem>
                                    <MenuItem value="Other">Other</MenuItem>
                                </Select>
                            )}
                        />
                    </FormControl>
                    <FormControl variant="outlined" fullWidth margin="normal">
                        <InputLabel htmlFor="attendees" className="TravelRequest__form-select-label">
                            Non-AFWERX Attendees on Trip?
                        </InputLabel>
                        <Controller
                            name="attendees"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    className="TravelRequest__form-select-field"
                                    id="attendees"
                                    data-testid="attendees-select"
                                    {...field}
                                >
                                    <MenuItem value="true">Yes</MenuItem>
                                    <MenuItem value="false">No</MenuItem>
                                </Select>
                            )}
                        />
                    </FormControl>
                </div>
                <FormControl variant="outlined" fullWidth margin="normal">
                    <Controller
                        name="noteToApprover"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                id="noteToApprover"
                                label="Additional notes"
                                variant="outlined"
                                multiline
                                rows={6}
                                error={!!errors.noteToApprover}
                                helperText={errors.noteToApprover?.message}
                            />
                        )}
                    />
                </FormControl>
                <div className="TravelRequest__actions">
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() =>
                            reset({
                                approver: null,
                                startDate: null,
                                endDate: null,
                                destination: '',
                                international: false,
                                purpose: '',
                                costEstimate: 0,
                                funding: '',
                                attendees: false,
                                noteToApprover: '',
                                traveler: '',
                            })
                        }
                    >
                        Clear
                    </Button>
                    <Button color="primary" variant="contained" disabled={!isValid} type="submit">
                        Review
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default TravelRequestForm;
