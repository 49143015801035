import { useQuery } from '@apollo/client';
import { AppBar, Button, Container, Snackbar, Toolbar, Typography } from '@material-ui/core';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import MuiAlert from '@material-ui/lab/Alert';
import { GET_STATUSES } from 'api/queries/status';
import { GET_USERS } from 'api/queries/user';
import Logout from 'auth/Logout';
import Navigation from 'components/Navigation/Navigation';
import _ from 'lodash';
import Admin from 'pages/Admin/Admin';
import Dashboard from 'pages/Dashboard/Dashboard';
import NotFound from 'pages/NotFound/NotFound';
import Profile from 'pages/Profile/Profile';
import TravelApproval from 'pages/TravelApproval/TravelApproval';
import TravelApprovalDetails from 'pages/TravelApproval/TravelApprovalDetails';
import TravelRequest from 'pages/TravelRequest/TravelRequest';
import React, { ReactElement } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { selectAlert, selectLoading, updateAlert, updateLoading, updateStatuses, updateUsers } from 'store/appSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Alert } from 'types/Alert';
import { Loading } from 'types/Loading';
import './App.scss';

export const DATE_FORMAT = 'YYYY/MM/DD';

const App = (): ReactElement => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const alert: Alert | null = useAppSelector(selectAlert);
    const loading: Loading = useAppSelector(selectLoading);
    const pathName = location.pathname.split('/')[1];
    let backgroundColor = 'transparent';
    if (pathName === 'profile') {
        backgroundColor = '#215173';
    }

    useQuery(GET_USERS, {
        fetchPolicy: 'network-only',
        onCompleted: (response: any) => {
            dispatch(updateUsers(_.orderBy(response.users, ['familyName'], ['asc'])));
            dispatch(updateLoading({ ...loading, users: false }));
        },
        onError: (error: any) => {
            console.log(error); // eslint-disable-line
            dispatch(updateAlert({ visible: true, message: 'Error Retrieving Users', severity: 'error' }));
            dispatch(updateLoading({ ...loading, users: false }));
        },
    });

    useQuery(GET_STATUSES, {
        fetchPolicy: 'network-only',
        onCompleted: (response: any) => {
            dispatch(updateStatuses(response.statuses));
            dispatch(updateLoading({ ...loading, statuses: false }));
        },
        onError: (error: any) => {
            console.log(error); // eslint-disable-line
            dispatch(updateAlert({ visible: true, message: 'Error Retrieving Status Objects', severity: 'error' }));
            dispatch(updateLoading({ ...loading, statuses: false }));
        },
    });

    return (
        <div className="App" data-testid="app">
            <AppBar className="App__header" position="sticky" elevation={0}>
                <Toolbar>
                    <Typography variant="h1">Travel Request</Typography>
                    <div>
                        <Button style={{ background: backgroundColor }} href="/profile">
                            <PersonOutlineOutlinedIcon />
                            Profile
                        </Button>
                        <Logout />
                    </div>
                </Toolbar>
                <Navigation />
            </AppBar>
            <main className="App__main">
                <Container maxWidth="xl">
                    <Switch>
                        <Route exact path="/">
                            <Dashboard />
                        </Route>
                        <Route exact path="/profile">
                            <Profile />
                        </Route>
                        <Route exact path="/request-travel/:id">
                            <TravelRequest mode="view" />
                        </Route>
                        <Route exact path="/request-travel/:id/edit">
                            <TravelRequest mode="edit" />
                        </Route>
                        <Route exact path="/request-travel/:id/review">
                            <TravelRequest mode="review" />
                        </Route>
                        <Route exact path="/request-travel">
                            <TravelRequest />
                        </Route>
                        <Route exact path="/approve-travel">
                            <TravelApproval />
                        </Route>
                        <Route exact path="/admin">
                            <Admin />
                        </Route>
                        <Route path="/approve-travel/:id">
                            <TravelApprovalDetails />
                        </Route>
                        <Route path="/admin">
                            <Admin />
                        </Route>
                        <Route path="*">
                            <NotFound />
                        </Route>
                    </Switch>
                </Container>
            </main>
            <Snackbar
                open={alert?.visible || false}
                autoHideDuration={6000}
                onClose={(): void => {
                    dispatch(updateAlert({ ...alert, visible: false }));
                }}
            >
                <MuiAlert variant="filled" severity={alert?.severity || 'info'}>
                    {alert?.message}
                </MuiAlert>
            </Snackbar>
        </div>
    );
};

export default App;
