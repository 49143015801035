import {
    ApolloClient,
    ApolloLink,
    ApolloProvider,
    HttpLink,
    InMemoryCache,
    ServerError,
    ServerParseError,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GraphQLError } from 'graphql';
import React, { ReactElement } from 'react';
import { initialState, updateCurrentUser, updateAlert } from 'store/appSlice';
import { useAppDispatch } from 'store/hooks';

interface Props {
    children: ReactElement;
}

export const networkErrorHandler = (networkError: Error | ServerError | ServerParseError): void => {
    console.error(`[Network error]: ${networkError}`); // eslint-disable-line
};

export const Providers = ({ children }: Props): ReactElement => {
    const dispatch = useAppDispatch();

    const graphQLErrorHandler = (graphQLErrors: GraphQLError[]): void => {
        graphQLErrors.forEach(({ message, locations, path, extensions }) => {
            if (
                (extensions?.code === 'UNAUTHENTICATED' &&
                    message.includes('User is not owner of this request or approver')) ||
                message.includes('User is not an approver')
            ) {
                console.error('UNAUTHENTICATED'); // eslint-disable-line
                dispatch(
                    updateAlert({
                        visible: true,
                        message: extensions?.exception.stacktrace[0],
                        severity: 'error',
                    })
                );
            } else if (extensions?.code === 'UNAUTHENTICATED') {
                // If the token is bad
                console.error('UNAUTHENTICATED'); // eslint-disable-line
                // Log out the user
                localStorage.clear();
                dispatch(updateCurrentUser(initialState.currentUser));
            } else if (message.includes('Access denied')) {
                // If the user does not have the correct role
                console.error('Access denied for this action!'); // eslint-disable-line
                // Log out the user
                localStorage.clear();
                dispatch(updateCurrentUser(initialState.currentUser));
            } else {
                const errorMessage = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
                console.error(errorMessage); // eslint-disable-line
            }
        });
    };
    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            graphQLErrorHandler([...graphQLErrors]);
        }

        if (networkError) {
            networkErrorHandler(networkError);
        }
    });

    const httpLink = new HttpLink({ uri: '/api' });
    const authMiddleware = new ApolloLink((operation, forward) => {
        // Add the google tokenId to the auth header
        operation.setContext(({ headers = {} }) => ({
            headers: {
                ...headers,
                authorization: localStorage.getItem('tokenId') ? `Bearer ${localStorage.getItem('tokenId')}` : null,
            },
        }));

        return forward(operation);
    });

    const client = new ApolloClient({
        cache: new InMemoryCache(),
        link: ApolloLink.from([authMiddleware, errorLink, httpLink]),
    });

    return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default Providers;
