import { Paper, Tab, Tabs, Toolbar } from '@material-ui/core';
import { TabContext } from '@material-ui/lab';
import Request from 'classes/Request';
import React, { ReactElement } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { selectCurrentUser } from 'store/appSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateCurrentRequest } from 'store/requestSlice';
import getUserApproverTypeNames from 'utils/getUserApproverTypeNames';
import './Navigation.scss';

const Navigation = (): ReactElement => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const currentUser = useAppSelector(selectCurrentUser);
    const url = location.pathname.split('/')[1];
    const tabValue = url || 'home';

    return (
        <Paper component="nav" square elevation={2}>
            <TabContext value={tabValue}>
                <Toolbar>
                    <Tabs
                        value={tabValue.includes('profile') ? false : tabValue}
                        textColor="primary"
                        aria-label="travel request navigation"
                    >
                        <Tab label="Home" aria-label="home" value="home" component={RouterLink} to="/" />
                        <Tab
                            label="Request Travel"
                            value="request-travel"
                            component={RouterLink}
                            to="/request-travel"
                            onClick={(): void => {
                                dispatch(updateCurrentRequest(Request.initialRequest(currentUser)));
                            }}
                        />
                        <Tab
                            className="Nav__approver"
                            label="Approver Dashboard"
                            value="approve-travel"
                            component={RouterLink}
                            to="/approve-travel"
                            disabled={getUserApproverTypeNames(currentUser).length === 0}
                        />
                        <Tab
                            className="Nav__admin"
                            label="Admin"
                            value="admin"
                            component={RouterLink}
                            to="/admin"
                            disabled={!getUserApproverTypeNames(currentUser).includes('Admin')}
                        />
                    </Tabs>
                </Toolbar>
            </TabContext>
        </Paper>
    );
};

export default Navigation;
