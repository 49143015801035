import React, { ReactElement, useEffect } from 'react';
import MuiTheme from 'MuiTheme';
import themes from 'assets/themes';

interface Props {
    children: ReactElement | ReactElement[];
}
const ThemeProvider = ({ children }: Props): ReactElement => {
    const setCSSVariables = (themeValues: { [key: string]: any }): void => {
        Object.keys(themeValues).forEach((key: string) => {
            document.documentElement.style.setProperty(`--${key}`, themes.light[key]);
        });
    };

    useEffect(() => {
        setCSSVariables(themes.light);
    });

    return <MuiTheme>{children}</MuiTheme>;
};

export default ThemeProvider;
