import React, { ReactElement } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

import './BaseDialog.scss';

interface Props {
    handleOpen: any;
    handleClose: any;
    title: string;
    contentText: string;
    content?: any;
    confirmText: string;
    cancelText?: string;
    handleConfirm: any;
    disabled?: any;
}

const BaseDialog = ({
    handleOpen,
    handleClose,
    title,
    contentText,
    content,
    confirmText,
    cancelText,
    handleConfirm,
    disabled,
}: Props): ReactElement => (
    <Dialog
        className="BaseDialog"
        data-testid="dialogWindow"
        role="main"
        open={handleOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle className="BaseDialog__title" id="alert-dialog-title">
            {title}
        </DialogTitle>

        <DialogContent className="BaseDialog__content">
            <DialogContentText id="alert-dialog-description">{contentText}</DialogContentText>
            {content}
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
                {cancelText || 'Cancel'}
            </Button>
            <Button onClick={handleConfirm} disabled={disabled} color="primary" variant="contained" autoFocus>
                {confirmText}
            </Button>
        </DialogActions>
    </Dialog>
);

export default BaseDialog;
