import React, { ReactElement } from 'react';
import { ThemeProvider, unstable_createMuiStrictModeTheme as createTheme } from '@material-ui/core/styles';

interface Props {
    children: ReactElement | ReactElement[];
}

export const common = {
    palette: {
        common: {
            black: '#0b0c0f',
            white: '#fafafa',
        },
    },
    typography: {
        fontSize: 14,
        fontFamily: '"Montserrat", "Trebuchet", "Helvetica", sans-serif',
        h1: {
            fontSize: '1.714rem',
            fontWeight: 700,
        },
        h2: {
            fontSize: '1.429rem',
            fontWeight: 600,
        },
        h3: {
            fontSize: '1.286rem',
            fontWeight: 600,
        },
        h4: {
            fontSize: '1.071rem',
        },
        button: {
            fontWeight: 600,
            textTransform: 'capitalize',
        },
    },
    overrides: {
        MuiTypography: {
            gutterBottom: {
                marginBottom: '16px',
            },
        },
    },
} as const;

export const dark = createTheme({
    // palette: {
    //     common: {
    //         black: common.palette.common.black,
    //         white: common.palette.common.white,
    //     },
    //     type: 'dark',
    //     primary: {
    //         main: '#6EB5F2',
    //         light: '#a1e8ff',
    //         dark: '#3b82bf',
    //         contrastText: '#ffffff',
    //     },
    //     secondary: {
    //         main: '#32d9ba',
    //         light: '#65ffed',
    //         dark: '#00a687',
    //         contrastText: '#ffffff',
    //     },
    //     error: {
    //         main: '#ff6262',
    //     },
    //     warning: {
    //         main: '#f2ac6e',
    //     },
    //     info: {
    //         main: '#6EB5F2',
    //     },
    //     background: {
    //         default: '#1b1d24',
    //         paper: '#41444E',
    //     },
    //     text: {
    //         primary: '#fafafa',
    //         secondary: '#a5b3bb',
    //         disabled: '#6f7178',
    //     },
    //     action: {
    //         hover: '#23489b',
    //         active: '#1660be',
    //         focus: '#3c8477',
    //         disabled: '#5f6065',
    //     },
    // },
    typography: common.typography,
    overrides: common.overrides,
});

export const light = createTheme({
    palette: {
        common: {
            black: common.palette.common.black,
            white: common.palette.common.white,
        },
        type: 'light',
        primary: {
            main: '#2b6f95',
            light: '#2f80a8',
            dark: '#215173',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#4DB6AC',
            light: '#B2DFDB',
            dark: '#009688',
            contrastText: '#0B0C0F',
        },
        error: {
            light: '#E57373',
            main: '#A92323',
            dark: '#D32F2F',
        },
        warning: {
            light: '#FFB74D',
            main: '#FF9800',
            dark: '#F57C00',
        },
        success: {
            light: '#81C784',
            main: '#2B642D',
            dark: '#388E3C',
        },
        info: {
            light: '#64B5F6',
            main: '#2196F3',
            dark: '#1976D2',
        },
        background: {
            default: '#f7f7f7',
            paper: '#ffffff',
        },
        text: {
            primary: '#123552',
            disabled: 'rgba(0, 0, 0, 0.26)',
        },
        action: {
            hover: 'rgba(0, 0, 0, 0.04)',
            active: 'rgba(114, 114, 114, 1)',
            selected: 'rgba(0, 0, 0, 0.08)',
            focus: 'rgba(0, 0, 0, 0.12)',
            disabled: 'rgba(0, 0, 0, 0.26)',
            disabledBackground: 'rgba(0, 0, 0, 0.12)',
        },
    },
    typography: common.typography,
    overrides: common.overrides,
});

const MuiTheme = ({ children }: Props): ReactElement => {
    const appliedTheme = createTheme(light);
    return <ThemeProvider theme={appliedTheme}>{children}</ThemeProvider>;
};

export default MuiTheme;
