import { gql } from '@apollo/client';

export const CREATE_REQUEST = gql`
    mutation CreateRequest($data: CreateRequestInput!) {
        createRequest(createRequestData: $data) {
            id
            traveler
            approverId
            approver {
                id
                givenName

                familyName
                email
                userApproverTypes {
                    approverType {
                        id
                        name
                    }
                }
                createdDate
                lastModifiedDate
            }
            destination
            startDate
            endDate
            purpose
            costEstimate
            funding
            attendees
            noteToApprover
            statusId
            status {
                id
                value
                title
                createdDate
                lastModifiedDate
            }
            approverComment
            history {
                id
                field
                oldValue
                newValue
                createdBy {
                    id
                    givenName
                    familyName
                    email
                    createdDate
                    lastModifiedDate
                }
            }
            createdById
            createdBy {
                id
                givenName

                familyName
                email
                userApproverTypes {
                    approverType {
                        id
                        name
                    }
                }
                createdDate
                lastModifiedDate
            }
            createdDate
            lastModifiedDate
        }
    }
`;

export const UPDATE_REQUEST = gql`
    mutation UpdateRequest($requestId: Float!, $data: UpdateRequestInput!) {
        updateRequest(requestId: $requestId, updateRequestData: $data) {
            id
            traveler
            approverId
            approver {
                id
                givenName

                familyName
                email
                userApproverTypes {
                    approverType {
                        id
                        name
                    }
                }
                createdDate
                lastModifiedDate
            }
            destination
            startDate
            endDate
            purpose
            costEstimate
            funding
            attendees
            noteToApprover
            statusId
            status {
                id
                value
                title
                createdDate
                lastModifiedDate
            }
            approverComment
            history {
                id
                field
                oldValue
                newValue
                createdBy {
                    id
                    givenName
                    familyName
                    email
                    createdDate
                    lastModifiedDate
                }
            }
            createdById
            createdBy {
                id
                givenName

                familyName
                email
                userApproverTypes {
                    approverType {
                        id
                        name
                    }
                }
                createdDate
                lastModifiedDate
            }
            createdDate
            lastModifiedDate
        }
    }
`;

export default { CREATE_REQUEST, UPDATE_REQUEST };
