import { gql } from '@apollo/client';

export const GET_USERS = gql`
    query GetUsers {
        users {
            id
            givenName
            familyName
            email
            userApproverTypes {
                approverTypeId
                approverType {
                    id
                    name
                }
            }
            lastLogin
            approverId
            createdDate
            lastModifiedDate
        }
    }
`;
export default { GET_USERS };
