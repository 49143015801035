export function setSessionStorageItem<T>(key: string, value: T): void {
    sessionStorage.setItem(key, JSON.stringify(value));
}

export function getSessionStorageItem<T>(key: string): T | null {
    try {
        const data: string | null = sessionStorage.getItem(key);
        if (data && data !== null) {
            return JSON.parse(data);
        }
        return null;
    } catch {
        return null;
    }
}

export function setLocalStorageItem<T>(key: string, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
}

export function getLocalStorageItem<T>(key: string): T | null {
    try {
        const data: string | null = localStorage.getItem(key);
        if (data && data !== null) {
            return JSON.parse(data);
        }
        return null;
    } catch {
        return null;
    }
}
