import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Alert } from 'types/Alert';
import { Loading } from 'types/Loading';
import { RootState } from 'store/store';
import { Settings } from 'types/Settings';
import { Status } from 'types/Status';
import { User } from 'types/User';

interface SliceState {
    settings: Settings;
    alert: Alert | null;
    users: User[];
    currentUser: User;
    statuses: Status[];
    loading: Loading;
}

export const initialState: SliceState = {
    settings: {
        theme: 'light',
    },
    alert: null,
    users: [],
    currentUser: {
        id: '',
        givenName: '',
        familyName: '',
        email: '',
        googleId: '',
        imageUrl: '',
        createdDate: '',
        lastModifiedDate: '',
        userApproverTypes: [],
        approverId: '',
    },
    statuses: [],
    loading: {
        travelerRequests: true,
        users: true,
        statuses: true,
    },
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        updateSettings: (state: any, action: PayloadAction<Settings>) => ({
            ...state,
            settings: action.payload,
        }),
        updateAlert: (state: any, action: PayloadAction<Alert>) => ({
            ...state,
            alert: action.payload,
        }),
        updateUsers: (state: any, action: PayloadAction<User[]>) => ({
            ...state,
            users: action.payload,
        }),
        updateCurrentUser: (state: any, action: PayloadAction<User | undefined>) => ({
            ...state,
            currentUser: action.payload,
        }),
        updateStatuses: (state: any, action: PayloadAction<Status[]>) => ({
            ...state,
            statuses: action.payload,
        }),
        updateLoading: (state: any, action: PayloadAction<Loading>) => ({
            ...state,
            loading: action.payload,
        }),
    },
});

// actions
export const { updateSettings, updateAlert, updateUsers, updateCurrentUser, updateStatuses, updateLoading } =
    appSlice.actions;

// selectors
export const selectSettings = (state: RootState): Settings => state.app.settings;
export const selectAlert = (state: RootState): Alert | null => state.app.alert;
export const selectUsers = (state: RootState): User[] => state.app.users;
export const selectCurrentUser = (state: RootState): User => state.app.currentUser;
export const selectStatuses = (state: RootState): Status[] => state.app.statuses;
export const selectLoading = (state: RootState): Loading => state.app.loading;

export default appSlice.reducer;
