import { gql } from '@apollo/client';

export const GET_STATUSES = gql`
    query GetStatuses {
        statuses {
            id
            value
            title
            createdDate
            lastModifiedDate
        }
    }
`;
export default { GET_STATUSES };
