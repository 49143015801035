import { GridColDef, GridValueGetterParams } from '@material-ui/data-grid';
import { DATE_FORMAT } from 'App';
import TravelDataGrid from 'components/TravelDataGrid/TravelDataGrid';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { expenseFormatterNoSymbol } from 'utils/formatter';
import './TravelDataGrid.scss';

interface Props {
    rows: any;
    showView?: boolean;
    showEdit?: boolean;
    loading?: boolean;
}

const RequestDataGrid = ({ rows, showView = true, showEdit = true, loading = false }: Props): ReactElement => {
    const columns: GridColDef[] = [
        {
            field: 'destination',
            headerName: 'Location(s)',
            flex: 1,
            minWidth: 200,
            valueGetter: (params: GridValueGetterParams) => {
                const { row } = params;
                return _.replace(row.destination, new RegExp('\\\n', 'g'), ' / ');
            },
        },
        {
            field: 'traveler',
            headerName: 'Traveler',
            flex: 1,
            minWidth: 200,
            valueGetter: (params: GridValueGetterParams) => {
                const { row } = params;
                return _.replace(row.traveler, new RegExp('\\\n', 'g'), ' / ');
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 200,
            valueGetter: (params: GridValueGetterParams) => {
                const { row } = params;
                return row.status?.title;
            },
            renderCell: (params: GridValueGetterParams) => {
                const { row } = params;
                const deniedStatus =
                    row.status && row.status.title === 'Denied' ? (
                        <Link data-testid="grid-status-link" to={`/request-travel/${row.id}`}>
                            {row.status.title}
                        </Link>
                    ) : (
                        row.status?.title
                    );
                return deniedStatus;
            },
        },
        {
            field: 'startDate',
            headerName: 'Start Date',
            width: 200,
            valueGetter: (params: GridValueGetterParams) => {
                const { row } = params;
                return `${dayjs(row.startDate).format(DATE_FORMAT)}`;
            },
        },
        {
            field: 'endDate',
            headerName: 'Return Date',
            width: 200,
            valueGetter: (params: GridValueGetterParams) => {
                const { row } = params;
                return `${dayjs(row.endDate).format(DATE_FORMAT)}`;
            },
        },
        {
            field: 'costEstimate',
            headerName: 'Est. Costs',
            width: 160,
            renderCell: (params) => {
                const { row } = params;
                const formattedExpense = expenseFormatterNoSymbol.format(+row.costEstimate);
                return (
                    <div className="FlexLayout">
                        <div className="TravelDataGrid__adornment">$</div>
                        <div data-testid="grid-cost-estimate">{formattedExpense}</div>
                    </div>
                );
            },
        },
        {
            field: 'funding',
            headerName: 'Funded By',
            width: 160,
        },
    ];

    return (
        <div className="RequestDataGrid" data-testid="request-data-grid">
            <TravelDataGrid columns={columns} rows={rows} showEdit={showEdit} showView={showView} loading={loading} />
        </div>
    );
};

export default RequestDataGrid;
