import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { RootState } from 'store/store';
import Request from 'classes/Request';

interface SliceState {
    currentRequest: Request;
    requests: Request[];
}

const initialState: SliceState = {
    currentRequest: Request.initialRequest(),
    requests: [],
};

export const requestSlice = createSlice({
    name: 'request',
    initialState,
    reducers: {
        updateCurrentRequest: (state: SliceState, action: PayloadAction<Request>) => ({
            ...state,
            currentRequest: Request.transformer(action.payload),
        }),
        addRequest: (state: SliceState, action: PayloadAction<Request>) => ({
            ...state,
            requests: [...state.requests, Request.transformer(action.payload)],
        }),
        updateRequest: (state: SliceState, action: PayloadAction<Request>) => ({
            ...state,
            requests: _.map(state.requests, (request: Request) =>
                request.id === action.payload.id ? Request.transformer(action.payload) : request
            ),
        }),
        updateRequests: (state: SliceState, action: PayloadAction<Request[]>) => ({
            ...state,
            requests: Request.transformer(action.payload),
        }),
        deleteRequest: (state: SliceState, action: PayloadAction<Request>) => ({
            ...state,
            requests: Request.transformer(
                _.filter(state.requests, (request: Request) => request.id !== action.payload.id)
            ),
        }),
    },
});

// actions
export const { updateCurrentRequest, addRequest, updateRequest, updateRequests, deleteRequest } = requestSlice.actions;

// selectors
export const selectCurrentRequest = (state: RootState): Request => state.request.currentRequest;
export const selectRequests = (state: RootState): Request[] => state.request.requests;

export default requestSlice.reducer;
