import { useMutation } from '@apollo/client';
import { UPSERT_USER } from 'api/mutations/user';
import Login from 'pages/Login/Login';
import React, { ReactElement, useEffect } from 'react';
import { selectCurrentUser, updateAlert, updateCurrentUser } from 'store/appSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { User } from 'types/User';
import refreshTokenSetup from 'utils/RefreshTokenSetup';
import { getLocalStorageItem, setLocalStorageItem } from 'utils/TypeSafeStorage';

interface Props {
    children: ReactElement;
}

export const GoogleProvider = ({ children }: Props): ReactElement => {
    let clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';
    if (window.location.hostname.includes('afwerx.com')) {
        clientId = '408745380202-0lc6rete54ms4uetcvh093bubp8atppc.apps.googleusercontent.com';
    } else if (window.location.hostname.includes('uat-ts-afwerx')) {
        clientId = '855275472543-3mdi58k2mscvq3e47t0fo3rg2v3ce4s6.apps.googleusercontent.com';
    } else if (window.location.hostname.includes('qa-ts-afwerx')) {
        clientId = '855275472543-g2lo484db5iofhvhres5j0fekikikq4u.apps.googleusercontent.com';
    } else if (window.location.hostname.includes('edge.mile-two.com')) {
        clientId = '468618377531-2pu95frfpvtkn8h3je51fm6rmo51bh1i.apps.googleusercontent.com';
    }
    const dispatch = useAppDispatch();
    const currentUser: User = useAppSelector(selectCurrentUser);

    const [upsertUser] = useMutation(UPSERT_USER, {
        onCompleted: (response): void => {
            // If initial login, user in storage will be null
            const initialLogin = !getLocalStorageItem('user');
            dispatch(updateCurrentUser(response.upsertUser as User));
            setLocalStorageItem('user', response.upsertUser as User);

            if (initialLogin) {
                dispatch(
                    updateAlert({
                        visible: true,
                        message: 'Log in Successful',
                        severity: 'success',
                    })
                );
            }
        },
        onError: (): void => {
            dispatch(
                updateAlert({
                    visible: true,
                    message: 'Error Accessing Travel Request Tool',
                    severity: 'error',
                })
            );
        },
    });

    const checkStorageDataForUserInfo = (): boolean =>
        !!(localStorage.getItem('tokenId') && localStorage.getItem('user'));

    const attemptToAutoLogin = async () => {
        // Check local storage to see if we can use that to attempt to automatically log the user in
        if (checkStorageDataForUserInfo()) {
            await upsertUser();
        }
    };

    const onLoginFailure = () => {
        dispatch(
            updateAlert({
                visible: true,
                message: 'Google authentication error',
                severity: 'error',
            })
        );
    };

    const onLoginSuccess = async (response: any) => {
        refreshTokenSetup(response);

        // Upsert into db
        await upsertUser();
    };

    useEffect(() => {
        attemptToAutoLogin();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // We have a currentUser in redux, which means we have completed the upsert
    if (currentUser.id) {
        return children;
    }

    // In this state, we have data in local storage, but have not yet completed our upsert call
    if (checkStorageDataForUserInfo()) {
        return <div />;
    }

    return <Login clientId={clientId} onLoginSuccess={onLoginSuccess} onLoginFailure={onLoginFailure} />;
};

export default GoogleProvider;
