import { common, light, dark } from 'MuiTheme';

export interface CSSVar {
    name: string;
    value: string;
}

const commonVars = {
    'font-size': common.typography.fontSize,
    'font-family': common.typography.fontFamily,
    black: common.palette.common.black,
    white: common.palette.common.white,
};

const themes: any = {
    dark: {
        ...commonVars,
        'primary-main': dark.palette.primary.main,
        'primary-light': dark.palette.primary.light,
        'primary-dark': dark.palette.primary.dark,
        'primary-contrastText': dark.palette.primary.contrastText,
        'secondary-main': dark.palette.secondary.main,
        'secondary-light': dark.palette.secondary.light,
        'secondary-dark': dark.palette.secondary.dark,
        'secondary-contrastText': dark.palette.secondary.contrastText,
        'background-default': dark.palette.background.default,
        'background-paper': dark.palette.background.paper,
        'text-primary': dark.palette.text.primary,
        'text-secondary': dark.palette.text.secondary,
        'text-disabled': dark.palette.text.disabled,
        'font-size': dark.typography.fontSize,
        'font-family': dark.typography.fontFamily,
        'action-active': dark.palette.action.active,
        'action-hover': dark.palette.action.hover,
        'action-focus': dark.palette.action.focus,
        'action-disabled': dark.palette.action.disabled,
        'details-background': '#343840',
        'appbar-background': '#1b1d24',
        'menu-link': common.palette.common.white,
        'menu-link-hover': '#32d9ba',
        'menu-link-hover-background': '#343840',
        'menu-button-hover': '#32D9BA',
        'header-background': common.palette.common.black,
        'warning-main': dark.palette.warning.main,
        'error-main': dark.palette.error.main,
        'success-main': dark.palette.success.main,
        'button-deselected': '#a5b3bb',
    },
    light: {
        ...commonVars,
        'primary-main': light.palette.primary.main,
        'primary-light': light.palette.primary.light,
        'primary-dark': light.palette.primary.dark,
        'primary-contrastText': light.palette.primary.contrastText,
        'secondary-main': light.palette.secondary.main,
        'secondary-light': light.palette.secondary.light,
        'secondary-dark': light.palette.secondary.dark,
        'secondary-contrastText': light.palette.secondary.contrastText,
        'background-default': light.palette.background.default,
        'background-paper': light.palette.background.paper,
        'text-primary': light.palette.text.primary,
        'text-secondary': light.palette.text.secondary,
        'text-disabled': dark.palette.text.disabled,
        'action-hover': light.palette.action.hover,
        'action-active': light.palette.action.active,
        'action-focus': light.palette.action.focus,
        'action-disabled': light.palette.action.disabled,
        'details-background': '#EBEEEF',
        'appbar-background': '#23489b',
        'menu-link': '#1660BE',
        'menu-link-hover': '#23489b',
        'menu-link-hover-background': '#e0e0e0',
        'menu-button-hover': '#32D9BA',
        'header-background': light.palette.background.paper,
        'warning-main': light.palette.warning.main,
        'error-main': light.palette.error.main,
        'success-main': light.palette.success.main,
        'button-deselected': '#6f7178',
    },
};

export default themes;
