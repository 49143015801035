import { Button } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { gapi } from 'gapi-script';
import React, { ReactElement } from 'react';
import { useGoogleLogout } from 'react-google-login';
import { initialState, updateCurrentUser } from 'store/appSlice';
import { useAppDispatch } from 'store/hooks';

export default (): ReactElement => {
    const dispatch = useAppDispatch();
    const onLogoutSuccess = () => {
        localStorage.clear();
        dispatch(updateCurrentUser(initialState.currentUser));
        const auth2 = gapi.auth2.getAuthInstance();
        if (auth2 != null) {
            auth2.signOut().then(auth2.disconnect());
        }
        window.location.reload();
    };
    let clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';
    if (window.location.hostname.includes('afwerx.com')) {
        clientId = '408745380202-0lc6rete54ms4uetcvh093bubp8atppc.apps.googleusercontent.com';
    } else if (window.location.hostname.includes('edge.mile-two.com')) {
        clientId = '468618377531-2pu95frfpvtkn8h3je51fm6rmo51bh1i.apps.googleusercontent.com';
    }

    const { signOut } = useGoogleLogout({
        clientId,
        onLogoutSuccess,
    });

    return (
        <Button className="App__header" onClick={signOut}>
            <ExitToAppIcon />
            Logout
        </Button>
    );
};
