import { Button, Container, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import './InvalidPermissions.scss';

const InvalidPermissions = (): ReactElement => {
    const history = useHistory();
    return (
        <Container maxWidth="sm" className="InvalidPermissions">
            <Typography variant="h2">Invalid permissions to view this page</Typography>
            <Button
                color="primary"
                variant="contained"
                onClick={() => {
                    history.push('/');
                }}
            >
                Return Home
            </Button>
        </Container>
    );
};

export default InvalidPermissions;
