import { GoogleLoginResponse } from 'react-google-login';

const refreshTokenSetup = (response: GoogleLoginResponse): void => {
    const defaultExpirationTime = 3600 - 5 * 60;

    // Timing to renew access token
    let refreshTiming = (response.tokenObj.expires_in || defaultExpirationTime) * 1000;

    const refreshToken = async () => {
        const newAuthRes = await response.reloadAuthResponse();
        refreshTiming = newAuthRes.expires_in || defaultExpirationTime;
        localStorage.setItem('tokenId', response?.tokenId);

        // Setup the other timer after the first one
        setTimeout(refreshToken, refreshTiming);
    };
    localStorage.setItem('tokenId', response?.tokenId);

    // Set up the refresh timer
    setTimeout(refreshToken, refreshTiming);
};

export default refreshTokenSetup;
