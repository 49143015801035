import { useLazyQuery } from '@apollo/client';
import { Button, Chip, Paper, Typography } from '@material-ui/core';
import { DataGrid, GridColDef, GridValueGetterParams } from '@material-ui/data-grid';
import { GET_REQUESTS, GET_REQUESTS_BY_APPROVER } from 'api/queries/request';
import { DATE_FORMAT } from 'App';
import Request from 'classes/Request';
import dayjs from 'dayjs';
import _ from 'lodash';
import InvalidPermissions from 'pages/InvalidPermissions/InvalidPermissions';
import React, { ReactElement, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { selectCurrentUser, selectStatuses, updateAlert } from 'store/appSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { expenseFormatterNoSymbol } from 'utils/formatter';
import getUserApproverTypeNames from 'utils/getUserApproverTypeNames';
import './TravelApproval.scss';

const TravelApproval = (): ReactElement => {
    const dispatch = useAppDispatch();
    const [travelRequests, setTravelRequests] = useState<Request[]>([]);
    const [pendingRequests, setPendingRequests] = useState<Request[]>([]);
    const [historyRequests, setHistoryRequests] = useState<Request[]>([]);
    const [currentRequests, setCurrentRequests] = useState<Request[]>([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [columns, setColumns] = useState<GridColDef[]>([]);
    const currentUser = useAppSelector(selectCurrentUser);
    const statuses = useAppSelector(selectStatuses);
    const isComponentVisible = getUserApproverTypeNames(currentUser).length > 0;
    const isAdmin: boolean = getUserApproverTypeNames(currentUser).includes('Admin');
    const isApproverType2: boolean = getUserApproverTypeNames(currentUser).includes('Approver Type 2');
    const isApproverType1: boolean = getUserApproverTypeNames(currentUser).includes('Approver Type 1');

    const updateColumns = (): void => {
        setColumns([
            {
                field: 'id',
                headerName: '  ',
                align: 'center',
                sortable: false,
                disableColumnMenu: true,
                width: 100,
                renderCell: (params) => {
                    const { row } = params;
                    return (
                        <Button
                            aria-label="View Request Details"
                            component={RouterLink}
                            to={`/approve-travel/${row.id}`}
                            color="primary"
                        >
                            Review
                        </Button>
                    );
                },
            },
            {
                field: 'traveler',
                headerName: 'Traveler',
                flex: 1,
                minWidth: 180,
                valueGetter: (params: GridValueGetterParams) => {
                    const { row } = params;
                    return row.traveler;
                },
            },
            {
                field: 'approver',
                headerName: 'Approver',
                flex: 1,
                minWidth: 180,
                valueGetter: (params: GridValueGetterParams) => {
                    const { row } = params;
                    return `${row.approver.familyName}, ${row.approver.givenName}`;
                },
            },
            {
                field: 'destination',
                headerName: 'Location(s)',
                flex: 1,
                minWidth: 180,
                valueGetter: (params: GridValueGetterParams) => {
                    const { row } = params;
                    return _.replace(row.destination, new RegExp('\\\n', 'g'), ' / ');
                },
            },
            {
                field: 'status',
                headerName: 'Status',
                flex: 1,
                minWidth: 180,
                valueGetter: (params: GridValueGetterParams) => {
                    const { row } = params;
                    return row.status.title;
                },
            },
            {
                field: 'createdDate',
                headerName: 'Submitted',
                type: 'date',
                flex: 1,
                minWidth: 180,
                valueGetter: (params: GridValueGetterParams) => {
                    const { row } = params;
                    return dayjs(row.createdDate).format(`${DATE_FORMAT} HH:mm`);
                },
            },
            {
                field: 'startDate',
                headerName: 'Start',
                type: 'date',
                flex: 1,
                minWidth: 180,
                valueGetter: (params: GridValueGetterParams) => {
                    const { row } = params;
                    return `${dayjs(row.startDate).format(DATE_FORMAT)}`;
                },
            },
            {
                field: 'endDate',
                headerName: 'Return',
                type: 'date',
                flex: 1,
                minWidth: 180,
                valueGetter: (params: GridValueGetterParams) => {
                    const { row } = params;
                    return `${dayjs(row.endDate).format(DATE_FORMAT)}`;
                },
            },
            {
                field: 'costEstimate',
                headerName: 'Exp Est',
                resizable: false,
                flex: 1,
                minWidth: 150,
                renderCell: (params) => {
                    const { row } = params;
                    const formattedExpense = expenseFormatterNoSymbol.format(+row.costEstimate);
                    return (
                        <div className="FlexLayout">
                            <div className="TravelApproval__adornment">$</div>
                            <div>{formattedExpense}</div>
                        </div>
                    );
                },
            },
            {
                field: 'funding',
                headerName: 'Funded By',
                flex: 1,
                minWidth: 180,
            },
        ]);
    };

    const [getRequestsByApprover] = useLazyQuery(GET_REQUESTS_BY_APPROVER, {
        fetchPolicy: 'network-only',
        onCompleted: (response) => {
            updateColumns();
            setTravelRequests(_.orderBy(Request.transformer(response.requestsByApprover), ['startDate'], ['asc']));
            setTableLoading(false);
        },
        onError: (error: any) => {
            console.log(error); // eslint-disable-line
            dispatch(updateAlert({ visible: true, message: 'Error Retrieving Travel Requests', severity: 'error' }));
        },
    });

    const [getRequests] = useLazyQuery(GET_REQUESTS, {
        fetchPolicy: 'network-only',
        onCompleted: (response) => {
            updateColumns();
            setTravelRequests(_.orderBy(Request.transformer(response.requests), ['startDate'], ['asc']));
            setTableLoading(false);
        },
        onError: (error: any) => {
            console.log(error); // eslint-disable-line
            dispatch(updateAlert({ visible: true, message: 'Error Retrieving Travel Requests', severity: 'error' }));
        },
    });

    useEffect(() => {
        if (currentUser.id && isComponentVisible) {
            if (isAdmin || isApproverType2) {
                getRequests();
            } else {
                getRequestsByApprover({
                    variables: {
                        approverId: currentUser.id,
                    },
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser.id]);

    useEffect(() => {
        setPendingRequests(
            _.filter(travelRequests, (request) => {
                if (isApproverType2 && !isAdmin) {
                    return request?.status?.id === statuses.find((status) => status.value === 'Approved Level 1')?.id;
                }

                return (
                    (isAdmin &&
                        request?.status?.id === statuses.find((status) => status.value === 'Approved Level 1')?.id) ||
                    request?.status?.id === statuses.find((status) => status.value === 'Pending')?.id ||
                    request?.status?.id === statuses.find((status) => status.value === 'Details Requested')?.id
                );
            })
        );
    }, [currentUser, isAdmin, isApproverType2, statuses, travelRequests]);

    useEffect(() => {
        setCurrentRequests(pendingRequests);
        let filteredRequests = _.filter(travelRequests, (request) => !pendingRequests.includes(request));
        if (isApproverType2 && !isAdmin) {
            filteredRequests = _.filter(
                filteredRequests,
                (request) => request?.status?.id !== statuses.find((status) => status.value === 'Pending')?.id
            );
        }
        setHistoryRequests(filteredRequests);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pendingRequests, travelRequests]);

    if (isComponentVisible) {
        return (
            <div className="TravelApproval">
                <Typography color="primary" variant="h2" gutterBottom>
                    Approver Dashboard
                </Typography>
                <Paper>
                    <div className="TravelApproval__header">
                        <Typography className="TravelApproval__title" color="primary" variant="h3">
                            Travel Requests
                        </Typography>
                        <div className="TravelApproval__header-chips">
                            {isApproverType1 && <Chip label="Approver Type 1" />}
                            {isApproverType2 && <Chip label="Approver Type 2" />}
                            {isAdmin && <Chip label="Admin" />}
                        </div>
                        <div className="TravelApproval__header-buttons">
                            <Button
                                className={
                                    currentRequests.length === pendingRequests.length
                                        ? `TravelApproval__header-selected`
                                        : 'TravelApproval__header-pending'
                                }
                                onClick={() => {
                                    setCurrentRequests(
                                        _.orderBy(Request.transformer(pendingRequests), ['startDate'], ['asc'])
                                    );
                                }}
                                color="primary"
                                variant="contained"
                            >
                                Pending Requests
                            </Button>
                            <Button
                                className={
                                    currentRequests.length === historyRequests.length
                                        ? `TravelApproval__header-selected`
                                        : 'TravelApproval__header-history'
                                }
                                onClick={() => {
                                    setCurrentRequests(
                                        _.orderBy(Request.transformer(historyRequests), ['startDate'], ['desc'])
                                    );
                                }}
                                color="primary"
                                variant="contained"
                            >
                                Request History
                            </Button>
                        </div>
                    </div>
                    <div className="TravelApproval__data">
                        <DataGrid
                            showCellRightBorder
                            rows={currentRequests}
                            columns={columns}
                            pageSize={10}
                            loading={tableLoading}
                            columnBuffer={9}
                            rowsPerPageOptions={[10]}
                        />
                    </div>
                </Paper>
            </div>
        );
    }

    return <InvalidPermissions />;
};

export default TravelApproval;
