import React, { ReactElement } from 'react';
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { Button, Paper, Snackbar, Typography } from '@material-ui/core';
import { DriveEtaOutlined, Flight, MoreHoriz, PublicOutlined } from '@material-ui/icons';
import MuiAlert from '@material-ui/lab/Alert';
import { selectAlert, updateAlert } from 'store/appSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Alert } from 'types/Alert';
import ThemeProvider from 'providers/Theme';
import './Login.scss';

interface Props {
    clientId: string;
    onLoginSuccess: ((response: GoogleLoginResponse | GoogleLoginResponseOffline) => void) | undefined;
    onLoginFailure: ((error: any) => void) | undefined;
}

const Login = ({ clientId, onLoginSuccess, onLoginFailure }: Props): ReactElement => {
    const dispatch = useAppDispatch();
    const alert: Alert | null = useAppSelector(selectAlert);

    return (
        <ThemeProvider>
            <div className="Login">
                <Paper className="Login__container">
                    <div className="Login__container-icons">
                        <Flight />
                        <MoreHoriz fontSize="small" />
                        <DriveEtaOutlined />
                        <MoreHoriz fontSize="small" />
                        <PublicOutlined />
                    </div>
                    <Typography variant="h1" color="textPrimary" align="center" gutterBottom>
                        Travel Request
                    </Typography>
                    <Typography variant="body2" color="textPrimary" align="center">
                        Request and approve travel, review travel trends, and track travel history.
                    </Typography>
                    <GoogleLogin
                        clientId={clientId || ''}
                        render={(renderProps) => (
                            <Button variant="contained" color="primary" onClick={renderProps.onClick}>
                                Log In
                            </Button>
                        )}
                        onSuccess={onLoginSuccess}
                        onFailure={onLoginFailure}
                        cookiePolicy="single_host_origin"
                    />
                </Paper>
                <Snackbar
                    open={alert?.visible || false}
                    autoHideDuration={6000}
                    onClose={(): void => {
                        dispatch(updateAlert({ ...alert, visible: false }));
                    }}
                >
                    <MuiAlert variant="filled" severity={alert?.severity || 'info'}>
                        {alert?.message}
                    </MuiAlert>
                </Snackbar>
            </div>
        </ThemeProvider>
    );
};
export default Login;
