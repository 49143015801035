import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import appReducer from 'store/appSlice';
import requestReducer from 'store/requestSlice';

export const store = configureStore({
    reducer: {
        app: appReducer,
        request: requestReducer,
    },
    middleware: getDefaultMiddleware({
        serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
