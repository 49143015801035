import React, { ReactElement, useEffect, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { DataGrid, GridCellParams, GridColDef, GridRowData } from '@material-ui/data-grid';

import { selectUsers } from 'store/appSlice';
import { useAppSelector } from 'store/hooks';
import BaseDialog from 'components/BaseDialog/BaseDialog';

import './TravelDataGrid.scss';

interface Props {
    rows: any;
    columns: GridColDef[];
    showView?: boolean;
    showEdit?: boolean;
    autoHeight?: boolean;
    loading?: boolean;
}

const TravelDataGrid = ({
    rows,
    columns,
    showView = true,
    showEdit = true,
    autoHeight = false,
    loading = false,
}: Props): ReactElement => {
    const history = useHistory();
    const users = useAppSelector(selectUsers);
    const [gridColumns, setGridColumns] = useState<GridColDef[]>([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [currentRow, setCurrentRow] = useState<GridRowData>();

    const handleRowEdit = (params: GridCellParams): void => {
        const { row } = params;
        setCurrentRow(row);
        if (row.status.value === 'Pending' || row.status.value === 'Details Requested') {
            history.push(`/request-travel/${row.id}/edit`);
        } else {
            setDialogOpen(true);
        }
    };

    useEffect(() => {
        if (users && users.length) {
            const actionColumn: GridColDef = {
                field: 'id',
                headerName: ' ',
                headerAlign: 'center',
                align: 'center',
                sortable: false,
                disableColumnMenu: true,
                minWidth: 150,
                renderCell: (params) => {
                    const { row } = params;
                    return (
                        <div>
                            {showView && (
                                <Button
                                    aria-label={`showView-${row.id}`}
                                    component={RouterLink}
                                    to={`/request-travel/${row.id}`}
                                    color="primary"
                                >
                                    View
                                </Button>
                            )}
                            {showEdit && (
                                <Button
                                    component="a"
                                    onClick={() => handleRowEdit(params)}
                                    disabled={
                                        row.status?.value === 'Approved Level 2' || row.status?.value === 'Denied'
                                    }
                                    color="primary"
                                    aria-label={`edit-${row.id}`}
                                >
                                    Edit
                                </Button>
                            )}
                        </div>
                    );
                },
            };
            if (showView || showEdit) {
                columns.unshift(actionColumn);
            }
            setGridColumns(columns);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users]);

    return (
        <>
            <div className="TravelDataGrid" data-testid="travel-data-grid">
                <DataGrid
                    hideFooter
                    showCellRightBorder
                    autoHeight={autoHeight}
                    rows={rows}
                    columns={gridColumns}
                    pageSize={10}
                    hideFooterSelectedRowCount
                    style={{ height: '100%' }}
                    loading={loading}
                    rowsPerPageOptions={[10]}
                    columnBuffer={9}
                />
            </div>
            <BaseDialog
                handleOpen={dialogOpen}
                handleClose={() => setDialogOpen(false)}
                title="Warning"
                contentText="This request is already in the approval process. If you edit the request, the approval process will reset upon submission. Are you sure you want to continue?"
                confirmText="Yes"
                handleConfirm={() => history.push(`/request-travel/${currentRow?.id}/edit`)}
            />
        </>
    );
};

export default TravelDataGrid;
