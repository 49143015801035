import { useQuery } from '@apollo/client';
import { Button, Paper, Tooltip, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { GET_REQUESTS_CREATED_BY_USER } from 'api/queries/request';
import Request from 'classes/Request';
import RequestDataGrid from 'components/TravelDataGrid/RequestDataGrid';
import _ from 'lodash';
import React, { ReactElement, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { selectCurrentUser, selectLoading, updateAlert, updateLoading } from 'store/appSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectRequests, updateCurrentRequest, updateRequests } from 'store/requestSlice';
import { Loading } from 'types/Loading';
import './Dashboard.scss';

const Dashboard = (): ReactElement => {
    const dispatch = useAppDispatch();
    const appRequests: Request[] = useAppSelector(selectRequests);
    const currentUser = useAppSelector(selectCurrentUser);
    const [travelRequests, setTravelRequests] = useState<Request[]>([]);
    const loading: Loading = useAppSelector(selectLoading);

    useEffect(() => {
        setTravelRequests(_.orderBy(appRequests, ['startDate'], ['desc']));
    }, [appRequests]);

    useQuery(GET_REQUESTS_CREATED_BY_USER, {
        variables: { userId: currentUser?.id },
        fetchPolicy: 'network-only',
        onCompleted: (response: any) => {
            dispatch(
                updateRequests(_.orderBy(Request.transformer(response.requestsCreatedByUser), ['startDate'], ['desc']))
            );
            dispatch(updateLoading({ ...loading, travelerRequests: false }));
        },
        onError: (error: any) => {
            console.log(error); // eslint-disable-line
            dispatch(updateAlert({ visible: true, message: 'Error Retrieving Travel Requests', severity: 'error' }));
            dispatch(updateLoading({ ...loading, travelerRequests: false }));
        },
    });

    return (
        <div className="Dashboard">
            <Typography color="primary" variant="h2" gutterBottom>
                Home
            </Typography>
            <div className="Dashboard__data">
                <Paper className="Dashboard__data-requests" elevation={1}>
                    <div className="Dashboard__header">
                        <Typography variant="h3">Travel Requests</Typography>
                        <Tooltip title="New Travel Request" placement="top">
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<AddIcon />}
                                component={RouterLink}
                                to="/request-travel"
                                onClick={(): void => {
                                    dispatch(updateCurrentRequest(Request.initialRequest(currentUser)));
                                }}
                            >
                                Add New
                            </Button>
                        </Tooltip>
                    </div>
                    <div className="Dashboard__main">
                        <RequestDataGrid rows={travelRequests} loading={loading.travelerRequests} />
                    </div>
                </Paper>
            </div>
        </div>
    );
};

export default Dashboard;
