import { RequestHistory } from 'types/RequestHistory';
import { Status } from 'types/Status';
import { User } from 'types/User';

interface ApiRequest {
    traveler: string;
    approverId: string | undefined;
    destination: string;
    international: boolean;
    startDate: string;
    endDate: string;
    purpose: string;
    costEstimate: number;
    funding: string;
    attendees: boolean;
    noteToApprover: string;
    statusId: number | undefined;
    approverComment: string;
}

export default class Request {
    id: string;
    traveler: string;
    approver: User | null;
    createdBy: User | null;
    destination: string;
    startDate: string | null;
    endDate: string | null;
    purpose: string;
    costEstimate: number | null;
    funding: string;
    international: boolean;
    attendees: boolean;
    noteToApprover: string;
    status: Status | null;
    approverComment: string;
    history: RequestHistory[] | null;
    createdDate: string;
    lastModifiedDate: string;

    constructor(data: Request) {
        this.id = data.id;
        this.traveler = data.traveler;
        this.approver = data.approver;
        this.createdBy = data.createdBy;
        this.destination = data.destination;
        this.startDate = data.startDate;
        this.endDate = data.endDate;
        this.purpose = data.purpose;
        this.costEstimate = data.costEstimate;
        this.funding = data.funding;
        this.international = data.international;
        this.attendees = data.attendees;
        this.noteToApprover = data.noteToApprover;
        this.status = data.status;
        this.approverComment = data.approverComment;
        this.history = data.history;
        this.createdDate = data.createdDate;
        this.lastModifiedDate = data.lastModifiedDate;
    }

    public static initialRequest(currentUser?: User): Request {
        return new Request({
            id: '',
            traveler: '',
            approver: null,
            createdBy: currentUser || null,
            destination: '',
            startDate: null,
            endDate: null,
            purpose: '',
            costEstimate: null,
            funding: '',
            international: false,
            attendees: false,
            noteToApprover: '',
            status: null,
            approverComment: '',
            history: null,
            createdDate: '',
            lastModifiedDate: '',
        });
    }

    public static transformer(data: Request | Request[]): any {
        if (data) {
            if (Array.isArray(data)) {
                return data.map((item: any) => new Request(item));
            }
            return new Request(data);
        }
        return undefined;
    }

    public static getMutation(data: Request): ApiRequest | undefined {
        return data
            ? {
                  traveler: data.traveler,
                  approverId: data.approver?.id,
                  destination: data.destination,
                  international: data.international,
                  startDate: data.startDate || '',
                  endDate: data.endDate || '',
                  purpose: data.purpose,
                  costEstimate: data.costEstimate ? +data.costEstimate : 0,
                  funding: data.funding,
                  attendees: data.attendees,
                  noteToApprover: data.noteToApprover,
                  // Default to pending status. Need to use id value directly here because hooks can only be used in react functions
                  statusId: data.status?.id || 1,
                  approverComment: data.approverComment,
              }
            : undefined;
    }
}
