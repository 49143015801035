import { gql } from '@apollo/client';

export const UPSERT_USER = gql`
    mutation UpsertUser {
        upsertUser {
            id
            googleId
            givenName
            familyName
            email
            imageUrl
            approverId
            userApproverTypes {
                id
                approverType {
                    id
                    name
                }
            }
        }
    }
`;

export const UPDATE_USER = gql`
    mutation UpdateUser($userId: String!, $data: UpdateUserInput!) {
        updateUser(userId: $userId, updateUserData: $data) {
            id
            googleId
            givenName
            familyName
            email
            imageUrl
            approverId
            userApproverTypes {
                id
                approverType {
                    id
                    name
                }
            }
        }
    }
`;

export default { UPSERT_USER, UPDATE_USER };
