import { Button, Divider, Typography } from '@material-ui/core';
import { DATE_FORMAT } from 'App';
import dayjs from 'dayjs';
import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { selectCurrentRequest } from 'store/requestSlice';
import { expenseFormatter } from 'utils/formatter';
import './TravelRequest.scss';

interface Props {
    onSubmit: () => void;
}

const TravelRequestReview = ({ onSubmit }: Props): ReactElement => {
    const history = useHistory();
    const travelRequest = useAppSelector(selectCurrentRequest);
    const formattedExpense = expenseFormatter.format(travelRequest.costEstimate ? +travelRequest.costEstimate : 0);

    return (
        <div className="TravelRequest__review">
            <Typography color="primary" variant="h3" gutterBottom>
                General Information
            </Typography>
            <div className="FlexLayout">
                <div className="FlexLayout__quarter">
                    <Typography variant="body1">Traveler</Typography>
                    <Typography variant="body2">{travelRequest.traveler}</Typography>
                </div>
                <div className="FlexLayout__quarter">
                    <Typography variant="body1">Prepared By</Typography>
                    <Typography variant="body2">{`${travelRequest.createdBy?.givenName} ${travelRequest.createdBy?.familyName}`}</Typography>
                </div>
                <div className="FlexLayout__quarter">
                    <Typography variant="body1">Approver</Typography>
                    <Typography variant="body2">{`${travelRequest.approver?.givenName} ${travelRequest.approver?.familyName}`}</Typography>
                </div>
                <div className="FlexLayout__quarter">
                    <Typography variant="body1">Trip Dates</Typography>
                    <Typography variant="body2" data-testid="travel-request-dates">
                        {dayjs(travelRequest.startDate).format(DATE_FORMAT)} &ndash;&nbsp;
                        {dayjs(travelRequest.endDate).format(DATE_FORMAT)}
                    </Typography>
                </div>
            </div>
            <div className="FlexLayout">
                <div className="FlexLayout__third">
                    <Typography variant="body1">Trip Location(s)</Typography>
                    <Typography variant="body2" className="TravelDestination">
                        {travelRequest.destination}
                    </Typography>
                </div>
                <div className="FlexLayout__third">
                    <Typography variant="body1">International Trip?</Typography>
                    <Typography variant="body2">{travelRequest?.international ? 'Yes' : 'No'}</Typography>
                </div>
                <div className="FlexLayout__third">
                    <Typography variant="body1">Reason for Trip</Typography>
                    <Typography variant="body2">{travelRequest.purpose}</Typography>
                </div>
            </div>
            <Divider variant="fullWidth" />
            <Typography color="primary" variant="h3">
                Expenses and Additional Details
            </Typography>
            <Typography variant="h5" gutterBottom color="textPrimary">
                Estimate must come from DTS
            </Typography>
            <div className="FlexLayout">
                <div className="FlexLayout__third">
                    <Typography variant="body1">Estimated Expenses</Typography>
                    <Typography variant="body2" data-testid="travel-request-cost-estimate">
                        {formattedExpense}
                    </Typography>
                </div>
                <div className="FlexLayout__third">
                    <Typography variant="body1">Who is Funding Trip?</Typography>
                    <Typography variant="body2">{travelRequest.funding || 'N/A'}</Typography>
                </div>
                <div className="FlexLayout__third">
                    <Typography variant="body1">Non-AFWERX Attendees on Trip?</Typography>
                    <Typography variant="body2" data-testid="travel-request-attendees">
                        {travelRequest.attendees ? 'Yes' : 'No'}
                    </Typography>
                </div>
            </div>
            <Typography variant="body1">Additional Notes</Typography>
            <Typography variant="body2">{travelRequest.noteToApprover || 'N/A'}</Typography>
            <div className="TravelRequest__actions">
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={(): void =>
                        history.push(
                            travelRequest.id ? `/request-travel/${travelRequest.id}/edit` : '/request-travel/0/edit'
                        )
                    }
                >
                    Back
                </Button>
                <Button color="primary" variant="contained" onClick={onSubmit}>
                    Submit
                </Button>
            </div>
        </div>
    );
};

export default TravelRequestReview;
