import { useLazyQuery, useMutation } from '@apollo/client';
import {
    Breadcrumbs,
    Button,
    CircularProgress,
    Container,
    Divider,
    Link,
    Paper,
    TextField,
    Typography,
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Autocomplete } from '@material-ui/lab';
import { UPDATE_REQUEST } from 'api/mutations/request';
import { GET_REQUESTS_CREATED_BY_USER, GET_REQUEST_BY_ID } from 'api/queries/request';
import { DATE_FORMAT } from 'App';
import Request from 'classes/Request';
import BaseDialog from 'components/BaseDialog/BaseDialog';
import dayjs from 'dayjs';
import _ from 'lodash';
import InvalidPermissions from 'pages/InvalidPermissions/InvalidPermissions';
import NotFound from 'pages/NotFound/NotFound';
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import { selectCurrentUser, selectStatuses, selectUsers, updateAlert } from 'store/appSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectCurrentRequest, updateCurrentRequest, updateRequest } from 'store/requestSlice';
import { ApproverType } from 'types/ApproverType';
import { Status } from 'types/Status';
import { User } from 'types/User';
import { expenseFormatter } from 'utils/formatter';
import getUserApproverTypeNames from 'utils/getUserApproverTypeNames';
import './TravelApprovalDetails.scss';

interface RouteParams {
    id: string;
}

const TravelApprovalDetails = (): ReactElement => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const users: User[] = useAppSelector(selectUsers);
    const statuses: Status[] = useAppSelector(selectStatuses);
    const travelRequest = useAppSelector(selectCurrentRequest);
    const [userRequestHistory, setUserRequestHistory] = useState<Request[]>([]);
    const [approvers, setApprovers] = useState<User[]>([]);
    const [approver, setApprover] = useState<any>();
    const [openDialog, setOpenDialog] = useState(false);
    const [approverComment, setApproverComment] = useState('');
    const [dialogType, setDialogType] = useState<'deny' | 'details'>('deny');
    const [loading, setLoading] = useState(true);
    const [responseNotFound, setResponseNotFound] = useState<boolean>(false);
    const { id } = useParams<RouteParams>();
    const formattedExpense = expenseFormatter.format(travelRequest.costEstimate ? +travelRequest.costEstimate : 0);
    const approverProps = {
        options: approvers,
        getOptionSelected: (option: User, value: User) => option.id === value.id,
        getOptionLabel: (option: User) =>
            option.familyName && option.givenName ? `${option.givenName} ${option.familyName}` : '',
    };
    const currentUser = useAppSelector(selectCurrentUser);
    const isComponentVisible = getUserApproverTypeNames(currentUser).length > 0;

    const getUserApproverTypes = (user: User): ApproverType[] =>
        user.userApproverTypes ? _.map(_.filter(user.userApproverTypes, 'approverType'), 'approverType') : [];

    const [getTravelRequestById] = useLazyQuery(GET_REQUEST_BY_ID, {
        fetchPolicy: 'network-only',
        onCompleted: (response) => {
            dispatch(updateCurrentRequest(response.request));
            setLoading(false);
            setResponseNotFound(false);
        },
        onError: () => {
            setResponseNotFound(true);
        },
    });
    const [getTravelRequestsByUserId] = useLazyQuery(GET_REQUESTS_CREATED_BY_USER, {
        variables: { userId: currentUser?.id },
        fetchPolicy: 'network-only',
        onCompleted: (response) => {
            const approvedRequests = _.filter(
                response.requestsCreatedByUser,
                (request: Request) => request.status?.value === 'Approved Level 2'
            );
            setUserRequestHistory(approvedRequests);
            setLoading(false);
        },
    });

    const [modifyRequest] = useMutation(UPDATE_REQUEST, {
        onCompleted: (response): void => {
            dispatch(updateRequest(Request.transformer(response.request)));
        },
        onError: (): void => {
            dispatch(
                updateAlert({
                    visible: true,
                    message: 'Error updating travel request',
                    severity: 'error',
                })
            );
        },
    });

    const handleOpenDialog = (type: 'deny' | 'details') => {
        setDialogType(type);
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
        setApproverComment('');
    };

    const handleChange = (event: any) => {
        if (event.target) {
            setApproverComment(event.target.value);
        }
    };
    const handleApproverError = () => travelRequest.approver?.id === travelRequest.createdBy?.id || false;

    const saveApprover = async (value: User) => {
        try {
            const newRequest = { ...travelRequest, approver: value };
            dispatch(updateCurrentRequest(newRequest));
            await modifyRequest({
                variables: {
                    requestId: newRequest.id,
                    data: Request.getMutation(newRequest),
                },
            });
            dispatch(
                updateAlert({
                    visible: true,
                    message: 'Approver successfully changed',
                    severity: 'success',
                })
            );
        } catch (error) {
            dispatch(
                updateAlert({
                    visible: true,
                    message: 'Error submitting travel approval, please try again',
                    severity: 'error',
                })
            );
            console.error(error); // eslint-disable-line no-console
        }
    };

    const submitApproverComment = async () => {
        try {
            if (approverComment.length) {
                const conditionalStatus =
                    dialogType === 'deny'
                        ? _.find(statuses, { value: 'Denied' })
                        : _.find(statuses, { value: 'Details Requested' });
                const newRequest: Request = {
                    ...travelRequest,
                    approverComment,
                    status: conditionalStatus || null,
                };
                await modifyRequest({
                    variables: {
                        requestId: travelRequest.id,
                        data: Request.getMutation(newRequest),
                    },
                });
                setOpenDialog(false);
                dispatch(
                    updateAlert({
                        visible: true,
                        message:
                            dialogType === 'deny' ? 'Travel request denied' : 'Request for more information submitted',
                        severity: 'success',
                    })
                );
                history.push('/approve-travel');
            } else {
                console.log('not submitted'); // eslint-disable-line no-console
            }
        } catch (error) {
            dispatch(
                updateAlert({
                    visible: true,
                    message: 'Error submitting travel approval, please try again',
                    severity: 'error',
                })
            );
            console.error(error); // eslint-disable-line no-console
        }
    };

    const submitApproval = async () => {
        try {
            let approvedStatus = _.find(statuses, { value: 'Approved Level 1' });
            if (
                _.find(getUserApproverTypeNames(currentUser), (type) => type === 'Admin' || type === 'Approver Type 2')
            ) {
                approvedStatus = _.find(statuses, { value: 'Approved Level 2' });
            }
            const newRequest: Request = {
                ...travelRequest,
                approverComment: '',
                status: approvedStatus || null,
            };
            await modifyRequest({
                variables: {
                    requestId: travelRequest.id,
                    data: Request.getMutation(newRequest),
                },
            });
            dispatch(
                updateAlert({
                    visible: true,
                    message: 'Travel request approved',
                    severity: 'success',
                })
            );
            history.push('/approve-travel');
            console.log('submit approval'); // eslint-disable-line no-console
        } catch (error) {
            dispatch(
                updateAlert({
                    visible: true,
                    message: 'Error submitting travel approval, please try again',
                    severity: 'error',
                })
            );
            console.error(error); // eslint-disable-line no-console
        }
    };

    useEffect(() => {
        if (id && isComponentVisible) {
            getTravelRequestById({
                variables: {
                    requestId: +id,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        setApprovers(
            _.filter(users, (user: User) =>
                getUserApproverTypes(user).some((userApproverType) => userApproverType.name === 'Approver Type 1')
            )
        );
        setApprover(travelRequest.approver);
        if (travelRequest.createdBy) {
            getTravelRequestsByUserId({
                variables: {
                    userId: travelRequest.createdBy?.id,
                },
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [travelRequest, users]);

    if (responseNotFound) {
        return <NotFound />;
    }

    if (isComponentVisible) {
        return (
            <Container maxWidth="lg" data-testid="travel-approval-details">
                {loading && (
                    <CircularProgress className="TravelApprovalDetails__loading" size={40} aria-label="Loading" />
                )}
                {!loading && (
                    <div className="TravelApprovalDetails">
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                            <Link component={RouterLink} to="/approve-travel">
                                Approver Dashboard
                            </Link>
                            <Typography color="primary">Trip Request Details</Typography>
                        </Breadcrumbs>
                        <div className="TravelApprovalDetails__header">
                            <Typography color="primary" variant="h2">
                                Trip Request Details
                            </Typography>
                        </div>
                        <div className="TravelApprovalDetails__main">
                            <div className="TravelApprovalDetails__main-details">
                                <Paper>
                                    <Typography variant="h3" gutterBottom>
                                        Reason for Trip
                                    </Typography>
                                    <Typography variant="body2">{travelRequest?.purpose || 'N/A'}</Typography>
                                </Paper>
                                <Paper>
                                    <Typography variant="h3" gutterBottom>
                                        General Information
                                    </Typography>
                                    <div className="FlexLayout__flexStart">
                                        <div className="FlexLayout__quarter">
                                            <Typography variant="body1">Traveler</Typography>
                                            <Typography variant="body2">{travelRequest.traveler}</Typography>
                                        </div>
                                        <div className="FlexLayout__quarter">
                                            <Typography variant="body1">Prepared By</Typography>
                                            <Typography variant="body2">
                                                {travelRequest.createdBy?.givenName}{' '}
                                                {travelRequest.createdBy?.familyName}
                                            </Typography>
                                        </div>
                                        <div className="FlexLayout__quarter">
                                            <Typography variant="body1">Approver</Typography>
                                            <Autocomplete
                                                {...approverProps}
                                                id="approver"
                                                disableClearable
                                                autoComplete
                                                data-testid="approver-input"
                                                size="small"
                                                includeInputInList
                                                value={approver || null}
                                                onChange={(_event: ChangeEvent<unknown>, value: User) =>
                                                    saveApprover(value)
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        data-testid="approver-text"
                                                        id="approver"
                                                        error={handleApproverError()}
                                                        helperText={
                                                            handleApproverError()
                                                                ? 'Cannot approve own Travel Request'
                                                                : ''
                                                        }
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className="FlexLayout__quarter">
                                            <Typography variant="body1">Trip Dates</Typography>
                                            <Typography variant="body2">
                                                {dayjs(travelRequest?.startDate).format(DATE_FORMAT)} &ndash;{' '}
                                                {dayjs(travelRequest?.endDate).format(DATE_FORMAT)}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className="FlexLayout__flexStart">
                                        <div className="FlexLayout__threeQuarters">
                                            <Typography variant="body1">Trip Location or Locations</Typography>
                                            <Typography variant="body2" className="TravelDestination">
                                                {travelRequest?.destination}
                                            </Typography>
                                        </div>
                                        <div className="FlexLayout__quarter">
                                            <Typography variant="body1">International Trip?</Typography>
                                            <Typography variant="body2">
                                                {travelRequest?.international ? 'Yes' : 'No'}
                                            </Typography>
                                        </div>
                                    </div>
                                    <Divider variant="fullWidth" />
                                    <Typography color="primary" variant="h3">
                                        Expenses and Additional Details
                                    </Typography>
                                    <Typography variant="h5" gutterBottom color="textPrimary">
                                        Estimate must come from DTS
                                    </Typography>
                                    <div className="FlexLayout__flexStart">
                                        <div className="FlexLayout__third">
                                            <Typography variant="body1">Estimated Expenses</Typography>
                                            <Typography variant="body2">{formattedExpense}</Typography>
                                        </div>
                                        <div className="FlexLayout__third">
                                            <Typography variant="body1">Who is Funding Trip?</Typography>
                                            <Typography variant="body2">{travelRequest?.funding}</Typography>
                                        </div>
                                        <div className="FlexLayout__third">
                                            <Typography variant="body1">Non-AFWERX Attendees on Trip?</Typography>
                                            <Typography variant="body2">
                                                {travelRequest?.attendees ? 'Yes' : 'No'}
                                            </Typography>
                                        </div>
                                    </div>
                                    <Typography variant="body1">Additional Notes</Typography>
                                    <Typography variant="body2">{travelRequest?.noteToApprover || 'N/A'}</Typography>
                                </Paper>
                            </div>
                            <div className="TravelApprovalDetails__main-actions">
                                <Button
                                    className="ApproveButton"
                                    variant="contained"
                                    color="primary"
                                    onClick={submitApproval}
                                    startIcon={<CheckCircleOutlineIcon />}
                                >
                                    Approve Trip Request
                                </Button>
                                <Button variant="outlined" color="primary" onClick={() => handleOpenDialog('details')}>
                                    Request Additional Details
                                </Button>
                                <Button
                                    className="DenyButton"
                                    variant="outlined"
                                    onClick={() => handleOpenDialog('deny')}
                                >
                                    Deny Trip Request
                                </Button>
                                <Paper>
                                    <Typography variant="body1">Prepared By</Typography>
                                    <Typography variant="h3" gutterBottom>
                                        {travelRequest.createdBy?.familyName}, {travelRequest.createdBy?.givenName}
                                    </Typography>
                                    <Divider />
                                    <Typography variant="body1">Trip History</Typography>
                                    <div
                                        className={
                                            (userRequestHistory?.length > 0 &&
                                                'TravelApprovalDetails__main-historyContainer') ||
                                            ''
                                        }
                                    >
                                        {userRequestHistory?.length > 0 ? (
                                            _.map(userRequestHistory, (request) => (
                                                <Typography
                                                    className="TravelApprovalDetails__main-history"
                                                    variant="body2"
                                                    key={request.id}
                                                >{`${dayjs(request?.startDate).format(DATE_FORMAT)} -
                                        ${dayjs(request?.endDate).format(DATE_FORMAT)} - ${expenseFormatter.format(
                                                    request.costEstimate ? +request.costEstimate : 0
                                                )} - ${request.destination} `}</Typography>
                                            ))
                                        ) : (
                                            <Typography variant="body2">N/A</Typography>
                                        )}
                                    </div>
                                </Paper>
                            </div>
                        </div>
                        <BaseDialog
                            handleOpen={openDialog}
                            handleClose={() => handleClose()}
                            title={dialogType === 'deny' ? 'Reason For Denial' : 'Request More Information'}
                            contentText={
                                (dialogType === 'deny' && 'Please provide a reason for denying this request:') ||
                                (dialogType === 'details' &&
                                    'Please specify what further information is needed to approve this request:') ||
                                ''
                            }
                            content={
                                <>
                                    {dialogType === 'deny' && (
                                        <TextField
                                            multiline
                                            rows={4}
                                            variant="outlined"
                                            margin="dense"
                                            id="approver_comments"
                                            label="Reason for denial"
                                            type="text"
                                            value={approverComment}
                                            onChange={handleChange}
                                            fullWidth
                                            required
                                        />
                                    )}
                                    {dialogType === 'details' && (
                                        <TextField
                                            multiline
                                            rows={4}
                                            variant="outlined"
                                            margin="dense"
                                            id="approver_details"
                                            label="Details"
                                            type="text"
                                            value={approverComment}
                                            onChange={handleChange}
                                            fullWidth
                                            required
                                        />
                                    )}
                                </>
                            }
                            confirmText={dialogType === 'deny' ? 'Deny Travel Request' : 'Send'}
                            handleConfirm={submitApproverComment}
                            disabled={approverComment.replace(/\s/g, '') === ''}
                        />
                    </div>
                )}
            </Container>
        );
    }
    return <InvalidPermissions />;
};

export default TravelApprovalDetails;
