import { User } from 'types/User';
import { UserApproverType } from 'types/UserApproverType';

export default (user: User): string[] => {
    const approverTypeNames: string[] = [];

    if (user) {
        if (user.userApproverTypes) {
            user.userApproverTypes.forEach((userApproverType: UserApproverType) => {
                approverTypeNames.push(userApproverType.approverType.name);
            });
        }
    }
    return approverTypeNames;
};
