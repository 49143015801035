import React, { ReactElement, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { FormControl, MenuItem, Paper, Select, Typography } from '@material-ui/core';
import { DataGrid, GridColDef, GridValueGetterParams } from '@material-ui/data-grid';
import dayjs from 'dayjs';
import _ from 'lodash';

import { selectCurrentUser, updateAlert } from 'store/appSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ApproverType } from 'types/ApproverType';
import { User } from 'types/User';
import { UserApproverType } from 'types/UserApproverType';
import getUserApproverTypeNames from 'utils/getUserApproverTypeNames';
import InvalidPermissions from 'pages/InvalidPermissions/InvalidPermissions';
import { CHANGE_USER_APPROVER_TYPE, DELETE_USERS_USER_APOPROVER_TYPES } from 'api/mutations/userApproverType';
import { GET_APPROVER_TYPES } from 'api/queries/approverType';
import { GET_USERS } from 'api/queries/user';
import { DATE_FORMAT } from 'App';

import './Admin.scss';

const Admin = (): ReactElement => {
    const dispatch = useAppDispatch();
    const [columns, setColumns] = useState<GridColDef[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [approverTypes, setApproverTypes] = useState<ApproverType[]>([]);
    const [tableLoading, setTableLoading] = useState(true);

    const currentUser = useAppSelector(selectCurrentUser);
    const isComponentVisible = getUserApproverTypeNames(currentUser).includes('Admin');

    const { refetch } = useQuery(GET_USERS, {
        skip: !isComponentVisible,
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted: (response) => {
            setUsers(_.orderBy(response.users, ['familyName'], ['asc']));
            updateColumns();
            setTableLoading(false);
        },
        onError: (error: any) => {
            console.log(error); // eslint-disable-line
            dispatch(updateAlert({ visible: true, message: 'Error Retrieving Users', severity: 'error' }));
        },
    });

    useEffect(() => {
        updateColumns();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [approverTypes]);

    // This is called when a user's role is changed to a Traveler
    const [deleteUsersUserApproverTypes] = useMutation(DELETE_USERS_USER_APOPROVER_TYPES, {
        onCompleted: (): void => {
            dispatch(
                updateAlert({
                    visible: true,
                    message: 'User role updated',
                    severity: 'success',
                })
            );
            refetch();
        },
        onError: (): void => {
            dispatch(
                updateAlert({
                    visible: true,
                    message: 'Error updating user role',
                    severity: 'error',
                })
            );
        },
    });

    const [changeUserApproverType] = useMutation(CHANGE_USER_APPROVER_TYPE, {
        onCompleted: (): void => {
            dispatch(
                updateAlert({
                    visible: true,
                    message: 'User role updated',
                    severity: 'success',
                })
            );
            refetch();
            updateColumns();
        },
        onError: (): void => {
            dispatch(
                updateAlert({
                    visible: true,
                    message: 'Error updating user role',
                    severity: 'error',
                })
            );
        },
    });

    const getRoleName = (userApproverTypes: UserApproverType[]): string => {
        // Right now we have a heirarchy of approverTypes by id.
        // id: 1 being the lowest and id: 4 being the highest
        let highestApproverType = { id: -1, name: 'Traveler' } as ApproverType;
        userApproverTypes.forEach((userApproverType: UserApproverType) => {
            if (userApproverType.approverType.id > highestApproverType.id) {
                highestApproverType = userApproverType.approverType;
            }
        });

        return highestApproverType.name;
    };

    const handleChange = (event: any, row: any) => {
        if (event.target) {
            const approverTypeName = event.target.value;
            if (approverTypeName === 'Traveler') {
                deleteUsersUserApproverTypes({
                    variables: {
                        userId: row.id,
                    },
                });
            } else {
                changeUserApproverType({
                    variables: {
                        userId: row.id,
                        approverTypeName,
                    },
                });
            }
        }
    };

    const updateColumns = (): void => {
        setColumns([
            {
                field: 'name',
                headerName: 'Name',
                flex: 1,
                valueGetter: (params: GridValueGetterParams) => {
                    const { row } = params;
                    return `${row.familyName}, ${row.givenName}`;
                },
            },
            {
                field: 'userApproverTypes',
                headerName: 'Role',
                flex: 1,
                valueGetter: (params: GridValueGetterParams) => {
                    const { row } = params;
                    return getRoleName(row.userApproverTypes);
                },
                renderCell: (params) => {
                    const { row } = params;
                    return (
                        <FormControl fullWidth>
                            <Select
                                className="TravelApproval__role"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={getRoleName(row.userApproverTypes)}
                                label="Role"
                                disableUnderline
                                data-testid="roleMenu"
                                // onChange={handleChange}
                                onChange={(e) => handleChange(e, row)}
                            >
                                <MenuItem key="Traveler" value="Traveler">
                                    Traveler
                                </MenuItem>
                                {approverTypes.map((approverType) => (
                                    <MenuItem key={approverType.name} value={approverType.name}>
                                        {approverType.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    );
                },
            },
            {
                field: 'lastActive',
                headerName: 'Last Active',
                flex: 1,
                valueGetter: (params: GridValueGetterParams) => {
                    const { row } = params;
                    return `${dayjs(row.lastLogin).format(DATE_FORMAT)}`;
                },
            },
        ]);
    };

    useQuery(GET_APPROVER_TYPES, {
        skip: !isComponentVisible,
        fetchPolicy: 'network-only',
        onCompleted: (response) => {
            setApproverTypes(response.approverTypes);
            setTableLoading(false);
        },
        onError: (error: any) => {
            console.log(error); // eslint-disable-line
            dispatch(updateAlert({ visible: true, message: 'Error Retrieving Users', severity: 'error' }));
        },
    });

    if (isComponentVisible) {
        return (
            <div className="TravelApproval">
                <Typography color="primary" variant="h2" gutterBottom>
                    Users & Roles
                </Typography>
                <Paper>
                    <div className="TravelApproval__header">
                        <Typography className="TravelApproval__title" color="primary" variant="h3">
                            Travel Requests
                        </Typography>
                    </div>
                    <div className="TravelApproval__data">
                        <DataGrid
                            showCellRightBorder
                            rows={users}
                            columns={columns}
                            pageSize={10}
                            loading={tableLoading}
                            columnBuffer={9}
                            rowsPerPageOptions={[10]}
                        />
                    </div>
                </Paper>
            </div>
        );
    }

    return <InvalidPermissions />;
};

export default Admin;
