import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { Button, FormControl, TextField, Typography, Paper, Container } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import _ from 'lodash';

import { ApproverType } from 'types/ApproverType';
import { selectCurrentUser, selectUsers, updateAlert, updateCurrentUser } from 'store/appSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { User } from 'types/User';

import './Profile.scss';
import { useMutation } from '@apollo/client';
import { UPDATE_USER } from 'api/mutations/user';
import { setLocalStorageItem } from 'utils/TypeSafeStorage';

interface IFormInputs {
    name: User | null;
    email: string | null;
    approver: User | null;
    emailNotifications: boolean;
    digestFrequency: string;
    frequency: number;
}

const TravelRequestForm = (): ReactElement => {
    const dispatch = useAppDispatch();
    const currentUser = useAppSelector(selectCurrentUser);
    const users = useAppSelector(selectUsers);
    const [approvers, setApprovers] = useState<User[]>([]);
    // Use separate var to track approver to enable a null value in autocomplete. This avoids an uncontrolled/controlled error.
    const [approver, setApprover] = useState<User>(_.filter(users, (user) => user.id === currentUser.approverId)[0]);
    // const [frequency, setFrequency] = useState<any>(0);

    const {
        control,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm<IFormInputs>({
        mode: 'all',
        criteriaMode: 'all',
        defaultValues: {
            name: currentUser,
            email: currentUser.email,
            approver,
            frequency: 0,
        },
    });

    const approverProps = {
        options: approvers,
        getOptionSelected: (option: User, value: User) => option.id === value.id,
        getOptionLabel: (option: User) =>
            option.familyName && option.givenName ? `${option.familyName}, ${option.givenName}` : '',
    };

    const getUserApproverTypes = (user: User): ApproverType[] =>
        user.userApproverTypes ? _.map(_.filter(user.userApproverTypes, 'approverType'), 'approverType') : [];

    const [updateUser] = useMutation(UPDATE_USER, {
        onCompleted: (response): void => {
            dispatch(updateCurrentUser({ ...currentUser, approverId: response.updateUser.approverId }));
            setLocalStorageItem('user', response.updateUser as User);
        },
        onError: (): void => {
            dispatch(
                updateAlert({
                    visible: true,
                    message: 'Error updating user info',
                    severity: 'error',
                })
            );
        },
    });

    const handleSave: SubmitHandler<IFormInputs> = async () => {
        if (isValid) {
            updateUser({
                variables: {
                    userId: currentUser.id,
                    data: {
                        approverId: currentUser.approverId,
                    },
                },
            });
            dispatch(
                updateAlert({
                    visible: true,
                    message: 'Information Updated',
                    severity: 'success',
                })
            );
        }
    };
    const handleInvalid = () => {
        dispatch(
            updateAlert({
                visible: true,
                message: 'Please complete all required fields',
                severity: 'error',
            })
        );
    };

    useEffect(() => {
        setApprover(_.filter(users, (user) => user.id === currentUser.approverId)[0]);
        setApprovers(
            _.filter(users, (user: User) => user.id !== currentUser.id && getUserApproverTypes(user).length > 0)
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users, currentUser]);

    return (
        <Container className="TravelRequest" maxWidth="md" id="profile">
            <Typography color="primary" variant="h2" gutterBottom>
                Profile
            </Typography>
            <Paper variant="outlined" elevation={3}>
                <div className="TravelRequest TravelRequest__form">
                    <form id="travelRequest" onSubmit={handleSubmit(handleSave)} onInvalid={handleInvalid}>
                        <Typography color="primary" variant="h3">
                            General Information
                        </Typography>
                        <div className="Profile__generalInput">
                            <FormControl fullWidth margin="dense">
                                <Controller
                                    name="name"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            id="name"
                                            variant="outlined"
                                            label="Name"
                                            margin="normal"
                                            disabled
                                            value={`${currentUser?.familyName}, ${currentUser?.givenName}`}
                                        />
                                    )}
                                />
                            </FormControl>
                            <FormControl fullWidth margin="dense">
                                <Controller
                                    name="email"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            id="email"
                                            variant="outlined"
                                            label="Email"
                                            margin="normal"
                                            disabled
                                            value={`${currentUser.email}`}
                                        />
                                    )}
                                />
                            </FormControl>

                            <FormControl fullWidth margin="dense">
                                <Controller
                                    name="approver"
                                    control={control}
                                    render={({ field }) => (
                                        <Autocomplete
                                            {...field}
                                            {...approverProps}
                                            data-testid="approver-input"
                                            aria-controls="approver"
                                            options={approvers}
                                            disableClearable
                                            autoComplete
                                            includeInputInList
                                            value={approver || null}
                                            onChange={(_event: ChangeEvent<unknown>, value: User): void => {
                                                setApprover(value);
                                                dispatch(updateCurrentUser({ ...currentUser, approverId: value.id }));
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    required
                                                    id="approver"
                                                    variant="outlined"
                                                    label="Approver"
                                                    margin="normal"
                                                    error={!!errors.approver}
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </FormControl>
                        </div>
                        {/* This feature is not supported by the DB yet */}
                        {/* <div className="FlexLayout__column">
                            <Typography color="primary" variant="h3">
                                Notifications Preferences
                            </Typography>
                            <div className="Profile__notifications">
                                <FormControl fullWidth margin="dense">
                                    <Controller
                                        name="emailNotifications"
                                        control={control}
                                        render={({ field }) => (
                                            <FormControlLabel
                                                {...field}
                                                control={
                                                    <Checkbox
                                                    // onChange={(_event: ChangeEvent<unknown>, value): void => {
                                                    //     dispatch(
                                                    //         updateCurrentUser({
                                                    //             ...currentUser,
                                                    //             emailNotifications: value,
                                                    //         })
                                                    //     );
                                                    // }}
                                                    />
                                                }
                                                label="Send me an email for each notification I receive"
                                            />
                                        )}
                                    />
                                </FormControl>
                                <div className="FlexLayout">
                                    <FormControl fullWidth margin="dense">
                                        <Controller
                                            name="digestFrequency"
                                            control={control}
                                            render={({ field }) => (
                                                <FormControlLabel
                                                    {...field}
                                                    className="Profile__notifications-digestFrequency"
                                                    control={
                                                        <Checkbox
                                                        // onChange={(_event: ChangeEvent<unknown>, value): void => {
                                                        //     dispatch(
                                                        //         updateCurrentUser({
                                                        //             ...currentUser,
                                                        //             digestFrequency: value,
                                                        //         })
                                                        //     );
                                                        // }}
                                                        />
                                                    }
                                                    label="Send me an email digest of my current notifications once a "
                                                />
                                            )}
                                        />
                                    </FormControl>
                                    <FormControl margin="dense">
                                        <Controller
                                            name="frequency"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    className="Profile__notifications-frequency"
                                                    variant="outlined"
                                                    labelId="frequency"
                                                    id="frequency"
                                                    value={frequency}
                                                    onChange={(
                                                        event: React.ChangeEvent<{
                                                            name?: string | undefined;
                                                            value: unknown;
                                                        }>
                                                    ): void => {
                                                        setFrequency(event.target.value);
                                                    }}
                                                >
                                                    <MenuItem value={0}>day</MenuItem>
                                                    <MenuItem value={1}>week</MenuItem>
                                                </Select>
                                            )}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </div> */}
                        <div className="TravelRequest__actions">
                            <Button color="primary" variant="contained" type="submit" disabled={!isValid}>
                                Save
                            </Button>
                        </div>
                    </form>
                </div>
            </Paper>
        </Container>
    );
};

export default TravelRequestForm;
