import ThemeProvider from 'providers/Theme';
import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import axe from '@axe-core/react';

import { store } from 'store/store';
import Apollo from 'providers/Apollo';
import GoogleProvider from 'providers/GoogleProvider';
import App from './App';
import reportWebVitals from './reportWebVitals';

if (process.env.NODE_ENV !== 'production') {
    axe(React, ReactDOM, 1000);
}

const app: ReactElement = (
    <React.StrictMode>
        <Provider store={store}>
            <Apollo>
                <GoogleProvider>
                    <ThemeProvider>
                        <Router>
                            <App />
                        </Router>
                    </ThemeProvider>
                </GoogleProvider>
            </Apollo>
        </Provider>
    </React.StrictMode>
);

document.addEventListener('DOMContentLoaded', function () {
    const mountNode = document.querySelector('#root');
    mountNode && ReactDOM.render(app, mountNode);
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
